import * as React from 'react'
import {useEffect, useState} from 'react'
import {GlobalState, globalStore} from "../../utils/redux/ReduxUtils";
import {connect} from "react-redux";
import './ProfileScreen.css'
import ContentScreen from "../ContentScreen";
import * as API from "../../api/API";
import {ReactTabulator} from "react-tabulator";
import {ContactModel} from "../../models/ContactModel";
import {useToasts} from "react-toast-notifications";
import {AddressModel} from "../../models/ExternalAdditionalInfo";
import {useHistory} from "react-router-dom";
import {ApiContactInfoResponseRow} from "../../api/ResponseModels";
import moment from "moment";
import {commonDateTimeFormat} from "../../utils/TypedUtils";

interface Props {
    addressesMap: Map<number, AddressModel>
}

const HistoryScreen = (props: Props) => {
    const [contacts, setContacts] = useState([] as ApiContactInfoResponseRow[])
    const {addToast} = useToasts()
    const history = useHistory();

    useEffect(() => {
        async function retrieveContactsAsync() {
            const contacts = await API.getContacts(globalStore.getState().authToken)
            setContacts(contacts)
        }

        retrieveContactsAsync().catch(e =>
            addToast('Не удалось загрузить историю контактов', {appearance: 'error'})
        )
    }, [])

    const onRowClicked = (e: any, row: any) => {
        const contact = row.getData() as ContactModel
        history.push("/myroute", {contactId: contact.id || 0}) // TODO may not work in offline mode
    }
    return (
        <ContentScreen>

            <div className='mx-auto w-100 mt-2 mb-2 d-flex gx-2 flex-column align-items-center'>
                <h2 className='row justify-content-center mt-2 mb-2'>История контактов</h2>
                <div className='col-md-8 col-12'>
                    <ReactTabulator
                        columns={[
                            {
                                title: 'Создан', field: 'createdAt',
                                formatter: (cell: any) => commonDateTimeFormat(moment(cell.getValue())),
                                sorter: (a: any, b: any) => moment(a).isAfter(moment(b))
                            },
                            {
                                title: 'ФИО', field: 'name',
                                formatter: (cell: any) => {
                                    const data = cell.getRow().getData()
                                    let resultString = cell.getValue()
                                    if (data.surname) resultString += " " + data.surname
                                    if (data.patronymic) resultString += " " + data.patronymic
                                    return resultString
                                }
                            },
                            {
                                title: 'Адрес', field: 'geo',
                                formatter: (cell: any) => {
                                    let resultString = ''
                                    const addressIdOpt = cell.getValue()?.addressId as number | undefined
                                    if (addressIdOpt) resultString += props.addressesMap.get(addressIdOpt)?.address
                                    return resultString
                                }
                            },
                            {
                                title: 'Квартира', field: 'geo',
                                formatter: (cell: any) => {
                                    return cell.getValue()?.flatNo as string | undefined
                                }
                            },
                        ]}
                        data={contacts} rowClick={onRowClicked}/>
                </div>
            </div>
        </ContentScreen>

    )
}

function mapStateToProps(state: GlobalState): Props {
    return {
        addressesMap: new Map(state.addresses.map(addr => [addr.id, addr]))
    }
}


export default connect(
    mapStateToProps
)(HistoryScreen);
