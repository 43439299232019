import {Age} from "../models/enums";
import moment from "moment";
import {ApiContactGeo} from "../api/RequestModels";

export function generateUUIDV4(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

export function getAgeDisplayName(r: Age, showFull: boolean = true): string {
    if (r === Age.YOUNG) {
        return "18 - 24";
    } else if (r === Age.PREADULT) {
        return "25 - 39"
    } else if (r === Age.ADULT) {
        return "40 - 54"
    } else if (r === Age.ELDER) {
        return "55 +"
    } else if (r === Age.UNKNOWN && showFull) {
        return 'Неизвестен'
    } else {
        return "???"
    }
}

export function commonDateTimeFormat(m: moment.Moment): string {
    const offset = new Date().getTimezoneOffset(); // TODO use this
    return m.format("DD.MM.YYYY HH:mm:ss")
}

export const makeFullAddressWithGeo = (fullAddress: String, geoOpt?: ApiContactGeo) => {
    if (!geoOpt) return fullAddress
    else return fullAddress + (geoOpt?.flatNo ? `, кв. ${geoOpt.flatNo}`: '')
}
