import * as React from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserAlt} from "@fortawesome/free-solid-svg-icons";
import {Button} from "react-bootstrap-v5";
import {GlobalState, globalStore} from "../../utils/redux/ReduxUtils";
import {connect} from "react-redux";
import './ProfileScreen.css'
import {useState} from "react";
import ContentScreen from "../ContentScreen";
import * as API from "../../api/API";
import {useToasts} from "react-toast-notifications";
import {APIUserInfo} from "../../api/Models";
import moment from "moment";
import {updateUserInfoAction} from "../../utils/redux/Actions";
import {isValidPhoneNumber} from "libphonenumber-js";
import PhoneInput from "react-phone-input-2";
import { thunkUpdateUserInfoAction } from '../../utils/redux/ThunkActions';

interface Props {
    user: APIUserInfo
}

const ProfileScreen = (props: Props) => {
    const { addToast } = useToasts()
    const [telegramNickname, setTelegramNickname] = useState(props.user.telegramNickname)
    const [error, setError] = useState('')
    const [phoneNumber, setPhoneNumber] = useState(props.user.phoneNumber)
    const [routesDirectionUpsideDown, setRoutesDirectionUpsideDown] = useState(props.user.routesDirectionUpsideDown)
    const nothingChanged =
        (telegramNickname === props.user.telegramNickname
            && phoneNumber === props.user.phoneNumber
            && routesDirectionUpsideDown === props.user.routesDirectionUpsideDown
        )
    const updateUserInfoViaAPI = async () => {
        if (!isValidPhoneNumber('+' + phoneNumber)) {
            setError('Пожалуйста, укажите правильный телефонный номер')
        } else {
            const result = await API.updateUserInfo({
                phoneNumber: phoneNumber,
                telegramNickname: telegramNickname,
                routesDirectionUpsideDown: routesDirectionUpsideDown,
            }, globalStore.getState().authToken)
            addToast('Успешно обновлено', {appearance: "success"})
            //@ts-ignore
            globalStore.dispatch(thunkUpdateUserInfoAction(result))
        }
    }

    return (
        <ContentScreen>
            <div className="row">
                <div className="d-flex flex-column align-items-center w-100">
                    <FontAwesomeIcon size='3x' color='black' icon={faUserAlt}/>
                    <div className="mt-2">{props.user.name}</div>
                    <div
                        className="mt-1 profile-text-sm">{`Зарегистрирован(а): ${moment(props.user.createdAt).format("DD.MM.YYYY")}`}</div>
                </div>
            </div>
            {
                error && <div className='mb-2 font-monospace fw-bold text-red text-center'>{error}</div>
            }
            <Button disabled={nothingChanged} onClick={updateUserInfoViaAPI} variant="outline-primary mt-3">Сохранить</Button>{' '}
            <div className="row mt-2 col-12 col-md-6">
                <div className="form-group col-12">
                    <div className="profile-input-label">Никнейм в Telegram:</div>
                    <input type="text"
                           className="form-control"
                           placeholder="@username"
                           value={telegramNickname}
                           onChange={e => setTelegramNickname(e.target.value)}/>
                </div>
                <div className="form-group col-12 mt-1">
                    <div className="profile-input-label">Телефон:</div>
                    <PhoneInput
                        placeholder="+7 999 777 66 55"
                        value={phoneNumber}
                        onChange={setPhoneNumber}/>
                </div>
                <div className="form-group col-12 d-flex align-items-center mt-2">
                    <input type="checkbox" className="form-check-input direction-upside-down-checkbox me-2"
                           onChange={() => setRoutesDirectionUpsideDown(!routesDirectionUpsideDown)}
                           checked={routesDirectionUpsideDown}
                           id="routesDirectionUpsideDown"/>
                    <label className="form-check-label" htmlFor="routesDirectionUpsideDown">Обход квартир
                        сверху-вниз</label>
                </div>

            </div>

        </ContentScreen>

    )
}

function mapStateToProps(state: GlobalState): Props {
    return {
        user: state.externalAdditionalInfo.userInfo,
    }
}

export default connect(
    mapStateToProps
)(ProfileScreen);
