import moment from "moment";

const ITEM_TOKEN = "authToken"
export const getLocalToken = () => window.localStorage.getItem(ITEM_TOKEN)
export const setLocalToken = (t: string | undefined) => {
    if (t) window.localStorage.setItem(ITEM_TOKEN, t)
    else window.localStorage.removeItem(ITEM_TOKEN)
}

export const getLastSyncMoment = () => {
    const v = localStorage.getItem("lastSyncMoment")
    if (v) return Number(v)
    else return undefined
};
export const setLastSyncMoment = (momentString: number) => {
    localStorage.setItem("lastSyncMoment", momentString.toString())
};
