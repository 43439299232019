import React, {PropsWithChildren, useState} from "react";
import {Modal} from "react-bootstrap-v5";
import NumericInput from "react-numeric-input";
import {ApiContactGeo} from "../../../api/RequestModels";
import AddressSearchTableFilter from "../search/AddressSearchTableFilter";
import {ToastConsumerContext} from "react-toast-notifications";
import {FilterOption} from "../search/TableSearchFilterView";

interface AddressEditModalValue {
    geo: ApiContactGeo
    fullAddress: string
}

interface Props {
    visible: boolean
    initialValue?: AddressEditModalValue
    toggleVisibility: () => any
    onSave: (s: State | undefined) => void
}

interface State {
    addressId: number
    fullAddress: string
    entranceNo?: number
    flatNo?: number
}

export default function AddressEditModal(props: PropsWithChildren<Props>) {
    const [address, setAddress] = useState<State | undefined>(props.initialValue ? {
        addressId: props.initialValue?.geo?.addressId,
        fullAddress: props.initialValue?.fullAddress,
        entranceNo: props.initialValue?.geo?.entranceNo,
        flatNo: props.initialValue?.geo?.flatNo,
    } : undefined)
    console.log('AddressEditModal', address, props.initialValue)

    const onAddressInputChanged = (v: FilterOption<number, string> | undefined) => {
        if (!v) setAddress(undefined)
        else {
            setAddress({
                addressId: v.key,
                fullAddress: v.value,
                entranceNo: 1,
                flatNo: 1,
            })
        }
    }

    const searchFilterValue: FilterOption<number, string> | undefined =
        address ? {
            key: address?.addressId,
            value: address?.fullAddress,
            label: address?.fullAddress
        } : undefined

    return (
        <Modal show={props.visible} onHide={props.toggleVisibility} centered>
            <Modal.Header>
                <Modal.Title>
                    <h5 className="modal-title text-green">Редактирование адреса</h5>
                </Modal.Title>
                <div className='d-flex flex-row align-items-center'>
                    <button type="button" className="btn-close" onClick={props.toggleVisibility}/>
                </div>
            </Modal.Header>
            <Modal.Body className='small'>
                <div className="col-12 row mb-2">
                    <AddressSearchTableFilter
                        value={searchFilterValue}
                        setValue={onAddressInputChanged}/>
                </div>
                <fieldset className='col-12 row' disabled={!Boolean(address?.addressId)}>
                    <div className='col-6 d-flex flex-column'>
                        <h5>Подъезд</h5>
                        <NumericInput
                            className="form-control"
                            min={1}
                            step={1}
                            onChange={n => address ? setAddress({
                                ...address,
                                entranceNo: Number.isInteger(n) && n ? n : undefined
                            }) : {}}
                            value={address?.entranceNo}
                            placeholder="№"/>
                    </div>
                    <div className='col-6 d-flex flex-column'>
                        <h5>Квартира</h5>
                        <NumericInput
                            className="form-control"
                            min={1}
                            step={1}
                            max={5000}
                            value={address?.flatNo}
                            onChange={n => address ? setAddress({
                                ...address,
                                flatNo: Number.isInteger(n) && n ? n : undefined
                            }) : {}}
                            placeholder="№"/>
                    </div>
                </fieldset>
            </Modal.Body>
            <Modal.Footer>
                <div className="col-12 col-sm">
                    <button onClick={props.toggleVisibility} type="button" className="w-100 btn btn-secondary"
                            data-bs-dismiss="modal">Закрыть
                    </button>
                </div>
                <div className="col-12 col-sm">
                    <button onClick={() => props.onSave(address)} type="button" className="w-100 mx-0 btn btn-green">
                        Применить
                    </button>
                </div>
            </Modal.Footer>
        </Modal>
    );

}
