export type APIUserInfo = {
    name: string
    createdAt: string
    phoneNumber: string
    telegramNickname?: string
    login: string
    routesDirectionUpsideDown: boolean
}
export type APIUserPayment = {
    value: number
    createdAt: string
}
export interface ApiCommonSettings {
    mapSettings: object
    possibleAges: string[]
    allowMultiThematicsSelect: boolean
    allowContactCreatedAtEditForScouts: boolean
    scoutHQCommunicationInfo: string
    allowContactsCreationWithoutPhoneNumber: boolean
}

export type APIExternalAdditionalInfo = {
    userInfo: APIUserInfo,
    thematics: string[]
    payments: APIUserPayment[]
    commonSettings: ApiCommonSettings
}

export type APIAuthResponse = {
    token: string
}

export type APIAddressesResponseAddress = {
    address: string
    entrancesCount?: number
    id: number
}
export type APIAddressesResponse = {
    rows: APIAddressesResponseAddress[]
}
export enum ContactGeoType {
    HOUSE_REQUEST_GEO = 'HouseRequestGeo'
}
