import {GlobalState, globalStore} from "../../utils/redux/ReduxUtils";
import * as React from 'react'
import {PropsWithChildren, useEffect, useState} from 'react'
import ContentScreen from "../ContentScreen";
import Select from "react-dropdown-select";
import './SelectSearch.scss'
import './MyRouteScreen.scss'
import NumericInput from "react-numeric-input";
import {Age, ContactType, Gender, Reaction} from "../../models/enums";
import {Button} from "react-bootstrap-v5";
import {AddressModel} from "../../models/ExternalAdditionalInfo";
import {connect} from "react-redux";
import {useToasts} from "react-toast-notifications";
import {Animated} from "react-animated-css";
import ContactInput from "./ContactInput";
import {ContactModel} from "../../models/ContactModel";
import moment from "moment";
import {ContactGeoType} from "../../api/Models";
import * as API from '../../api/API'
import * as ApiRequestMappers from '../../api/ApiRequestMappers'
import {faMapMarkedAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import AppSpinner from "../components/AppSpinner";
import {useHistory} from "react-router-dom";
import {
    ApiAppAgitationRemainingResponseRow,
    ApiAppHouseEntranceRecentContactsResponseRow,
    ApiContactInfoResponseRow
} from "../../api/ResponseModels";
import {AgitationGiveSection} from "../components/route/AgitationGiveSection";
import {ContactStorable} from "../../models/DbStorableModels";
import * as TypedUtils from "../../utils/TypedUtils";
import {thunkSaveLocalContactsAction} from "../../utils/redux/ThunkActions";
import * as ApiResponseMappers from '../../api/ApiResponseMappers'
import Icon from "@iconify/react";
import duplicate24 from '@iconify-icons/octicon/duplicate-24';
import {ApiContactGeo} from "../../api/RequestModels";

interface Props {
    addresses: AddressModel[],
    agitation: ApiAppAgitationRemainingResponseRow[],
    initialContact?: ContactModel
}

interface FlatAndAddress {
    flatNo?: number
    addressId: number
    fullAddress: string
    entranceNo?: number
}

const THEMATICS_UNKNOWN = 'Неизвестна'
const EMPTY_CONTACT_MODEL: ContactModel = {
    id: 0,
    name: '',
    gender: Gender.FEMALE,
    reaction: Reaction.GOOD,
    thematics: [THEMATICS_UNKNOWN],
    age: Age.ADULT, // dont change or handle properly in contactInput
    contactType: ContactType.STREET_PROMO,
}
let CONTACT_TYPE_NAMES: { [key: string]: string } = {}
CONTACT_TYPE_NAMES[ContactType.ODD] = 'ОДД'
CONTACT_TYPE_NAMES[ContactType.STREET_PROMO] = 'Улица: Промо'
CONTACT_TYPE_NAMES[ContactType.STREET_CUBE] = 'Улица: Куб'

function makeContactModel(THEMATICS: string[], contactType: ContactType, flatAndAddress?: FlatAndAddress): ContactModel {
    return {
        ...EMPTY_CONTACT_MODEL,
        thematics: THEMATICS.length > 0 ? [THEMATICS[0]] : [THEMATICS_UNKNOWN],
        contactType: contactType,
        fullAddress: flatAndAddress?.fullAddress,
        geo: flatAndAddress ? {
            addressId: flatAndAddress.addressId,
            entranceNo: flatAndAddress.entranceNo,
            flatNo: flatAndAddress.flatNo,
            type: ContactGeoType.HOUSE_REQUEST_GEO
        } : undefined

    }
}

const MyRouteScreen = (props: PropsWithChildren<Props>) => {
    const {addToast} = useToasts()
    const history = useHistory();
    const authToken = globalStore.getState().authToken
    const THEMATICS = globalStore.getState().externalAdditionalInfo.thematics
    const isUpsideDown = globalStore.getState().externalAdditionalInfo.userInfo.routesDirectionUpsideDown
    const routeType = globalStore.getState().routeSettings?.routeType || ContactType.STREET_PROMO
    const IS_ODD_MODE = routeType === ContactType.ODD
    const [selectedAddress, setSelectedAddress] = useState(undefined as AddressModel | undefined)
    const [recentContactCounts, setRecentContactCounts] = useState([] as ApiAppHouseEntranceRecentContactsResponseRow[])
    const [editableContactIdx, setEditableContactIdx] = useState(0)
    const makeNewContactModel = (sampleContact?: ContactModel) => {
        return makeContactModel(THEMATICS, routeType, selectedAddress ? {
            flatNo: sampleContact?.geo?.flatNo || (IS_ODD_MODE ? isUpsideDown ? 100 : 1 : undefined),
            addressId: selectedAddress?.id,
            fullAddress: selectedAddress?.address,
            entranceNo: sampleContact?.geo?.entranceNo || (IS_ODD_MODE ? 1 : undefined)
        } : undefined)
    }
    const [contacts, setContacts] = useState(
        [EMPTY_CONTACT_MODEL] as ContactModel[]
    )
    console.log('contacts', contacts)
    const firstContact = contacts[0]
    const setEntranceNo = (entranceNo: number) => {
        if (!firstContact.geo) return
        else {
            const modifiedFirstContactGeo: ApiContactGeo = {
                ...firstContact.geo,
                entranceNo: entranceNo
            }
            setContacts(contacts.map(c => {
                return {
                    ...c,
                    ...{
                        geo: modifiedFirstContactGeo
                    }
                }
            }))
        }
    }
    const setFlatNo = (flatNo: number) => {
        if (!firstContact.geo) return
        else {
            const modifiedFirstContactGeo: ApiContactGeo = {
                ...firstContact.geo,
                flatNo: flatNo
            }
            setContacts(contacts.map(c => {
                return {
                    ...c,
                    ...{
                        geo: modifiedFirstContactGeo
                    }
                }
            }))
        }
    }
    const [rawApiContact, setRawApiContact] = useState(undefined as ApiContactInfoResponseRow | undefined)

    // @ts-ignore
    const initialContactIdOpt: number | undefined = props.location.state?.contactId //from history
    const [initialContact, setInitialContact] = useState(undefined as ContactModel | undefined)

    const isExistingContactEdit = Boolean(initialContactIdOpt)
    useEffect(() => {
        setContacts([makeNewContactModel()])
        const loadData = async () => {
            if (initialContactIdOpt) {
                const response = await API.getContactById(initialContactIdOpt, globalStore.getState().authToken)
                const contactModelFromApi = ApiResponseMappers.mapApiContactToContact(response)
                setRawApiContact(response)
                setInitialContact(contactModelFromApi)
                setContacts([contactModelFromApi])
            }
        }
        loadData().catch(e => addToast('Не удалось загрузить контакт, попробуйте переоткрыть страницу!', {appearance: "error"}))
    }, [])

    const onAddressSelected = (newAddress?: AddressModel) => {
        setSelectedAddress(newAddress)
        let modifiedFirstContactGeo: ApiContactGeo | undefined = undefined
        if (!selectedAddress && newAddress) {
            modifiedFirstContactGeo = {
                entranceNo: 1,
                flatNo: isUpsideDown ? 100 : 1,
                addressId: newAddress.id,
                type: ContactGeoType.HOUSE_REQUEST_GEO
            }
        } else if (newAddress && firstContact?.geo) {
            modifiedFirstContactGeo = {...firstContact.geo, addressId: newAddress.id}
        }
        const modifiedContacts = contacts.map(c => {
            return {
                ...c,
                fullAddress: newAddress?.address,
                ...{
                    geo: modifiedFirstContactGeo
                }
            }
        })
        setContacts(modifiedContacts)
    }

    const loadRecentContactsForEntranceInfo = async () => {
        if (selectedAddress && firstContact?.geo) {
            const res = await API.getRecentContactsForHouseAndEntrance(
                selectedAddress.id,
                firstContact?.geo?.entranceNo || 1,
                globalStore.getState().authToken
            )
            setRecentContactCounts(res.rows)
        }
    }
    useEffect(() => {
        loadRecentContactsForEntranceInfo()
    }, [firstContact?.geo?.entranceNo, selectedAddress?.id])


    const [addressPanelVisible, setAddressPanelVisible] = useState(!isExistingContactEdit)

    const onAddContactPressed = () => {
        const newContacts = contacts.concat([makeNewContactModel(contacts[editableContactIdx])])
        setContacts(newContacts)
        setEditableContactIdx(newContacts.length - 1)
    }

    const onContactEdited = (newContact: ContactModel) => {
        let newContacts = [...contacts]
        newContacts[editableContactIdx] = newContact
        setContacts(newContacts)
    }
    const onContactDelete = async () => {
        let newContacts = [...contacts]
        const deletableContact = newContacts[editableContactIdx]
        if (deletableContact.id) {
            try {
                await API.deleteContact(deletableContact.id, authToken)
                if (isExistingContactEdit) history.push('/history')
            } catch (e) {
                addToast('Не удалось удалить контакт!', {appearance: "error"})
            }
        }
        newContacts.splice(editableContactIdx, 1);
        setEditableContactIdx(editableContactIdx - 1 > 0 ? editableContactIdx - 1 : 0)
        setContacts(newContacts)
    }
    const resetContactsState = () => {
        const currentIdx = editableContactIdx
        setEditableContactIdx(0)
        setContacts([makeNewContactModel(contacts[currentIdx])])
    }
    const onCancelPressed = () => {
        if (isExistingContactEdit) {
            history.push('/history')
        } else if (!IS_ODD_MODE) {
            history.goBack()
        } else {
            resetContactsState()
            setAddressPanelVisible(true)
        }
    }

    const onContactsSavedSuccessfullyResetState = () => {
        resetContactsState()
        window.scrollTo(0, 0)
        if (IS_ODD_MODE) {
            const firstContactFlatNo = firstContact.geo?.flatNo || 1
            setFlatNo(firstContactFlatNo - 1 >= 1 ? firstContactFlatNo - 1 : 1)
            setAddressPanelVisible(true)
            loadRecentContactsForEntranceInfo()
        }
    }


    const onSaveContactsPressed = async () => {
        try {
            await API.createContacts({
                contacts: contacts.map(c =>
                    ApiRequestMappers.mapContactToRequest(
                        c,
                        c.geo
                    )
                )
            }, authToken)
            if (isExistingContactEdit) {
                addToast(`Контакт успешно обновлён`, {appearance: "success"})
                history.push('/history')
            } else {
                addToast(`Успешно создано контактов: ${contacts.length}`, {appearance: "success"})
                onContactsSavedSuccessfullyResetState()
            }

        } catch (err) {
            if (err.message === 'NetworkError when attempting to fetch resource.') {
                const modelsForSave: ContactStorable[] = contacts.map(c => {
                    return {...c, ...{localUUID: TypedUtils.generateUUIDV4(), geo: c.geo}}
                })
                // @ts-ignore
                globalStore.dispatch(thunkSaveLocalContactsAction(modelsForSave))
                addToast('Нет связи с сервером, контакты сохранены локально', {appearance: 'warning'})
                onContactsSavedSuccessfullyResetState()
            } else {
                addToast('Не удалось сохранить контакты!', {appearance: "error"})
            }
            console.log(err)
        }
    }
    const onCopyAddressToClipboardClicked = async () => {
        await navigator.clipboard.writeText(selectedAddress?.address || '')
        addToast('Адрес скопирован в буфер обмена', {appearance: "success"})
    }
    const toggleShowContactInput = () => {
        setAddressPanelVisible(false)
    }

    const contactInputVisibilityCond = !addressPanelVisible && (isExistingContactEdit ? Boolean(initialContact) : true)

    return (
        <ContentScreen>
            <h2 className='row justify-content-center'>{isExistingContactEdit ? 'Редактирование контакта' : CONTACT_TYPE_NAMES[routeType]}</h2>
            <Animated
                className='col-12 col-md-6 row'
                animationIn="fadeIn" animationOut="fadeOutLeft" animationInDuration={1000}
                animationOutDuration={1000} isVisible={addressPanelVisible}>
                <div className='col-10'>
                    <Select
                        closeOnSelect
                        searchable
                        searchBy={'address'}
                        labelField={'address'}
                        valueField={'id'}
                        options={props.addresses}
                        onChange={(vs: AddressModel[]) => {
                            if (vs.length > 0) onAddressSelected(vs[0])
                            else onAddressSelected(undefined)
                        }} placeholder="Выберите адрес..."
                        values={selectedAddress ? [selectedAddress] : []}/>
                    {
                        recentContactCounts.length > 0 && IS_ODD_MODE &&
                        <div>
                            <span>Контакты в этом подъезде за неделю:</span>
                            <ul className={'list-group'}>
                                {
                                    recentContactCounts.map(row =>
                                        <li className='list-group-item col-6 d-flex justify-content-between py-1'>
                                            {`${moment(row.date).format('YYYY.MM.DD')}: `}
                                            <span className={'text-green'}>{`${row.count}`}</span>
                                        </li>
                                    )
                                }
                            </ul>
                        </div>
                    }
                    {
                        selectedAddress &&
                        <a target="_blank" rel="noopener noreferrer"
                           className='mt-2'
                           href={`https://yandex.ru/maps/?text=${selectedAddress?.address || ''}`}>
                            <FontAwesomeIcon className='me-1 text-primary' icon={faMapMarkedAlt}/>
                            Показать на карте
                        </a>
                    }
                </div>
                {
                    selectedAddress &&
                    <div className='col-2' onClick={() => onCopyAddressToClipboardClicked()}>
                        <Icon className='cursor-pointer'
                              icon={duplicate24}
                              width={20}
                              color={`#206bc4`}
                        />
                    </div>
                }


                {
                    selectedAddress && IS_ODD_MODE &&
                    <div
                        className='max-w-md mx-auto w-100 mt-2 mb-2 d-flex gx-2 align-items-end justify-content-evenly'>
                        <div className='d-flex flex-column text-center'>
                            <label className='mb-1'>Подъезд:</label>
                            <NumericInput mobile
                                          value={contacts[editableContactIdx].geo?.entranceNo}
                                          max={selectedAddress?.entrancesCount || 100}
                                          min={1} step={1}
                                          onChange={v => setEntranceNo(v || 1)} className='my-route-numeric-input'
                            />
                        </div>
                        <div className='d-flex flex-column text-center'>
                            <label className='mb-1'>Квартира:</label>
                            <NumericInput mobile
                                          value={contacts[editableContactIdx].geo?.flatNo}
                                          max={5000}
                                          min={1}
                                          step={1}
                                          onChange={v => setFlatNo(v || 1)} className='my-route-numeric-input'
                            />
                        </div>
                    </div>
                }

                <div className={'max-w-md mx-auto w-100 mt-2 mb-2 d-flex gx-2 align-items-end justify-content-evenly'}>
                    <Button onClick={toggleShowContactInput}
                            disabled={IS_ODD_MODE && !Boolean(selectedAddress)}
                            className='col-8' variant='primary' size='lg'>
                        <div>
                            {IS_ODD_MODE || selectedAddress
                                ? 'К контактам'
                                : 'Продолжить без адреса'
                            }
                        </div>
                    </Button>
                </div>
                {
                    ((IS_ODD_MODE && selectedAddress?.id)) &&
                    <div className={'mt-5'}>
                        <AgitationGiveSection
                            houseId={selectedAddress?.id}
                            entranceNo={contacts[editableContactIdx].geo?.entranceNo}
                            agitation={props.agitation}/>
                    </div>
                }

            </Animated>
            <Animated animationInDelay={isExistingContactEdit ? 0 : 1000}
                      animationIn="fadeIn"
                      animationOut="fadeOutLeft" animationInDuration={1000}
                      isVisible={contactInputVisibilityCond}>
                <ContactInput onSaveContact={onSaveContactsPressed}
                              contactsCount={contacts.length}
                              contactNo={editableContactIdx}
                              rawApiContact={rawApiContact}
                              contact={contacts[editableContactIdx]}
                              onAddContactPressed={initialContactIdOpt ? undefined : onAddContactPressed}
                              onContactEdited={onContactEdited}
                              onContactDelete={onContactDelete}
                              onNextArrowPressed={() => setEditableContactIdx(editableContactIdx + 1)}
                              onBackArrowPressed={() => setEditableContactIdx(editableContactIdx - 1)}
                              onCancelPressed={onCancelPressed}
                              agitation={props.agitation}
                />
            </Animated>
            {
                !contactInputVisibilityCond && !addressPanelVisible &&
                <AppSpinner/>
            }

        </ContentScreen>
    )
}


function mapStateToProps(state: GlobalState): Props {
    return {
        addresses: state.addresses,
        agitation: state.agitation
    }
}

export default connect(
    mapStateToProps
)(MyRouteScreen);
