import React, {useEffect, useState} from "react";
import ContentScreen from "../ContentScreen";
import {globalStore} from "../../utils/redux/ReduxUtils";


interface Props {
}

export const HqCommunicationScreen = (props: Props) => {
    const communicationInfoString = globalStore.getState().externalAdditionalInfo.commonSettings.scoutHQCommunicationInfo
    return (
        <ContentScreen>
            <div className="w-100 d-flex flex-column align-items-center pt-4">
                {
                    communicationInfoString ?
                        <>
                            <h4>Связь со штабом:</h4>
                            <h5 style={{whiteSpace: 'pre-line', textAlign: 'center'}}>{communicationInfoString}</h5>
                        </>
                        : <h4>Контакты для обратной связи не указаны</h4>
                }
            </div>
        </ContentScreen>
    )
}
