import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as _ from "lodash";

export interface FilterOption<K, V> {
    key: K
    value: V
    label: string
}

interface State<K, V> {
    options: FilterOption<K, V>[]
    isLoading: boolean
    isOpened: boolean
    searchQuery: string
}

interface Props<K, V> {
    title: string
    value?: FilterOption<K, V>
    setValue: (v: FilterOption<K, V> | undefined) => void
    searchRows: (searchQueryOpt?: string) => Promise<FilterOption<K, V>[]>
}

class TableSearchFilterView<K, V> extends React.Component<Props<K, V>, State<K, V>> {
    constructor(props: Props<K, V>) {
        super(props);
        this.state = ({
            options: [],
            isLoading: false,
            isOpened: false,
            searchQuery: ''
        })
    }

    _onOpen = () => {
        this.setState({isOpened: true, isLoading: true}, async () => {
            this._debouncedSearchRowsForQuery()
        })
    }
    _onClose = () => {
        this.setState({isOpened: false, options: [], isLoading: false})
    }
    _debouncedSearchRowsForQuery = _.debounce(async () => {
        const rows = await this.props.searchRows(this.state.searchQuery).catch(err => {
            console.log(err)
            return []
        })
        this.setState({
            options: rows,
            isLoading: false
        })
    }, 500)

    onSearchQueryChanged = (q: string) => {
        if (this.state.isOpened) {
            this.setState({searchQuery: q, isLoading: true}, () => {
                this._debouncedSearchRowsForQuery()
            })
        }

    }

    render() {
        return (
            <Autocomplete
                size={'small'}
                open={this.state.isOpened}
                onOpen={this._onOpen}
                onClose={this._onClose}
                onChange={(event: any, newValue: FilterOption<K, V> | null) => {
                    this.props.setValue(newValue || undefined)
                }}
                getOptionSelected={(option, value) => option.key === value.key}
                getOptionLabel={(option) => option.label}
                options={this.state.options}
                loading={this.state.isLoading}
                onInputChange={(event, newSearchQuery) => {
                    this.onSearchQueryChanged(newSearchQuery)
                }}
                value={this.props.value}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={this.props.title}
                        variant="outlined"
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <div>
                                    {this.state.isLoading ? <CircularProgress color="inherit" size={20}/> : null}
                                    {params.InputProps.endAdornment}
                                </div>

                            ),
                        }}
                    />
                )}
            />
        );
    }

}

export default TableSearchFilterView

