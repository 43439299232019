import {applyMiddleware, createStore} from 'redux'
import thunk from "redux-thunk";
import {
    ReducerAction,
    UPDATE_AGITATION_DATA,
    UPDATE_AUTH_TOKEN,
    UPDATE_LOADED_DATA,
    UPDATE_ROUTE_SETTINGS, UPDATE_UNSENT_COUNT,
    UPDATE_USER_INFO
} from "./types";
import {RouteSettings} from "./models";
import {AddressModel} from "../../models/ExternalAdditionalInfo";
import * as LocalStorage from '../LocalStorage'
import {ContactType} from "../../models/enums";
import {ApiAppAgitationRemainingResponseRow} from "../../api/ResponseModels";
import {APIExternalAdditionalInfo} from "../../api/Models";

export interface GlobalState {
    externalAdditionalInfo: APIExternalAdditionalInfo
    addresses: AddressModel[]
    agitation: ApiAppAgitationRemainingResponseRow[]
    routeSettings: RouteSettings
    unsentRowsCount: number
    authToken: string
}

const EMPTY_GLOBAL_STATE: GlobalState = {
    authToken: LocalStorage.getLocalToken(),
    unsentRowsCount: 0,
    routeSettings: {
        routeType: ContactType.ODD
    }
} as GlobalState // should never be really used

function myReducer(state: GlobalState = EMPTY_GLOBAL_STATE, action: ReducerAction) {
    switch (action.type) {
        case UPDATE_LOADED_DATA:
            return {
                ...state,
                externalAdditionalInfo: action.payload.info,
                addresses: action.payload.addresses,
                agitation: action.payload.agitation
            }
        case UPDATE_AGITATION_DATA:
            return {
                ...state,
                agitation: action.payload
            }
        case UPDATE_UNSENT_COUNT:
            return {
                ...state,
                unsentRowsCount: action.payload
            }
        case UPDATE_AUTH_TOKEN:
            if (action.payload) {
                LocalStorage.setLocalToken(action.payload)
            } else LocalStorage.setLocalToken(undefined)
            return {...state, authToken: action.payload}
        case UPDATE_USER_INFO:
            return {...state, externalAdditionalInfo: {...state.externalAdditionalInfo, user: action.payload}}
        case UPDATE_ROUTE_SETTINGS:
            return {...state, routeSettings: action.payload}
        default:
            return state;
    }
}

export const globalStore = createStore(
    myReducer,
    EMPTY_GLOBAL_STATE,
    applyMiddleware(thunk)
);

