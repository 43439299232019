import {
    AddressModel,
} from "../../models/ExternalAdditionalInfo";
import {
    UPDATE_AGITATION_DATA,
    UPDATE_AUTH_TOKEN,
    UPDATE_LOADED_DATA,
    UPDATE_ROUTE_SETTINGS,
    UPDATE_USER_INFO,
    UpdateAgitationDataAction,
    UpdateAuthTokenAction,
    UpdateLoadedDataAction,
    UpdateRouteSettingsAction,
    UpdateUserInfoAction
} from "./types";
import {RouteSettings} from "./models";
import {ApiAppAgitationRemainingResponseRow} from "../../api/ResponseModels";
import {APIExternalAdditionalInfo, APIUserInfo} from "../../api/Models";

export function updateLoadedDataAction(
    info: APIExternalAdditionalInfo,
    addresses: AddressModel[],
    agitation: ApiAppAgitationRemainingResponseRow[]
): UpdateLoadedDataAction {
    return {
        type: UPDATE_LOADED_DATA,
        payload: {
            info: info,
            addresses: addresses,
            agitation: agitation
        }
    }
}

export function updateUserInfoAction(data: APIUserInfo): UpdateUserInfoAction {
    return {
        type: UPDATE_USER_INFO,
        payload: data
    }
}

export function updateRouteSettings(settings: RouteSettings): UpdateRouteSettingsAction {
    return {
        type: UPDATE_ROUTE_SETTINGS,
        payload: settings
    }
}

export function updateAuthToken(token: string): UpdateAuthTokenAction {
    return {
        type: UPDATE_AUTH_TOKEN,
        payload: token
    }
}

export function updateAgitationData(agitation: ApiAppAgitationRemainingResponseRow[]): UpdateAgitationDataAction {
    return {
        type: UPDATE_AGITATION_DATA,
        payload: agitation
    }
}
