import {openDB} from 'idb';
import {DBGlobalSettingsModel} from "../models/DBGlobalSettingsModel";
import {AgitationDistributionStorable, ContactStorable} from "../models/DbStorableModels";

const DB_VERSION_NUMBER = 2
export const SEND_MATERIALS_STORE = 'sendMaterialsStore'
export const SEND_CONTACTS_STORE = 'sendContactsStore'
export const GLOBAL_SETTINGS_STORE = 'globalSettingsDataStore'

export async function ensureDB() {
    //check for support
    if (!('indexedDB' in window)) {
        console.log('This browser doesn\'t support IndexedDB');
        throw new Error('Этот браузер не поддерживает локальное сохранение данных!')
    }
    const db = await openDB('electme', DB_VERSION_NUMBER, {
        async upgrade(db, oldVersion) {
            console.log('Upgrading DB, old version', oldVersion)
            if (oldVersion < 1) {
                console.log('Upgrade db to v1')
                db.createObjectStore(SEND_MATERIALS_STORE, {autoIncrement: true})
                db.createObjectStore(SEND_CONTACTS_STORE, {autoIncrement: true})
            }
            if (oldVersion < 2) {
                console.log('Upgrade db to v2')
                db.createObjectStore(GLOBAL_SETTINGS_STORE, {autoIncrement: true})
            }

        },
    })
    return db
}
export async function upsertGlobalSettings(v: DBGlobalSettingsModel): Promise<DBGlobalSettingsModel> {
    const db = await ensureDB();
    const tx = db.transaction(GLOBAL_SETTINGS_STORE, 'readwrite')
    await tx.store.delete(1)
    const dbKeyResult = await tx.store.put(JSON.stringify(v), 1)
    const result = JSON.parse(await tx.store.get(1)) as DBGlobalSettingsModel
    await tx.done
    console.log(`Upserted global settings to db`);
    return result
}
export async function getGlobalSettings(): Promise<DBGlobalSettingsModel> {
    const db = await ensureDB();
    const raw = await db.get(GLOBAL_SETTINGS_STORE, 1)
    if (!raw) return Promise.reject("Локальные настройки не найдены")
    return JSON.parse(raw) as DBGlobalSettingsModel
}
export async function getTotalUnsentRowsCount(): Promise<number> {
    const db = await ensureDB();
    const materials = await db.count(SEND_MATERIALS_STORE)
    const contacts = await db.count(SEND_CONTACTS_STORE)
    return materials + contacts
}
export async function insertMaterialDistributionRow(v: AgitationDistributionStorable) {
    const db = await ensureDB();
    const dbKeyResult = await db.add(SEND_MATERIALS_STORE, v, v.localUUID)
    console.log(`Added item to storage with key ${v.localUUID}`, v);
}

export async function deleteUnsentMaterialRow<T extends string | number>(key: T) {
    const db = await ensureDB();
    const tx = db.transaction(SEND_MATERIALS_STORE, 'readwrite')
    const store = tx.objectStore(SEND_MATERIALS_STORE)
    await store.delete(key)
    await tx.done
    console.log('Removed material from storage for key', key);
}

export async function getAllUnsentMaterials(): Promise<AgitationDistributionStorable[]> {
    let db = await ensureDB();
    const tx = db.transaction(SEND_MATERIALS_STORE, 'readwrite');
    const store = tx.objectStore(SEND_MATERIALS_STORE);
    return await store.getAll() as AgitationDistributionStorable[]
}

export async function getAllUnsentContacts(): Promise<ContactStorable[]> {
    let db = await ensureDB();
    const tx = db.transaction(SEND_CONTACTS_STORE, 'readwrite');
    const store = tx.objectStore(SEND_CONTACTS_STORE);
    const raws =  await store.getAll() as string[]
    const models = raws.map(r => JSON.parse(r) as ContactStorable)
    return models
}

export async function insertUnsentContactRow(v: ContactStorable) {
    const db = await ensureDB();
    const dbKeyResult = await db.add(SEND_CONTACTS_STORE, JSON.stringify(v), v.localUUID)
    console.log(`Added item to storage with key ${v.localUUID}`, v);
}

export async function deleteUnsentContactRow<T extends string | number>(key: T) {
    const db = await ensureDB();
    const tx = db.transaction(SEND_CONTACTS_STORE, 'readwrite')
    const store = tx.objectStore(SEND_CONTACTS_STORE)
    await store.delete(key)
    await tx.done
    console.log('Removed contact from storage for key', key);
}
