export enum Gender {
    MALE = 'male',
    FEMALE = 'female'
}

export enum Age {
    YOUNG = 'young',
    PREADULT = 'preAdult',
    ADULT = 'adult',
    ELDER = 'elder',
    UNKNOWN = 'unknown'
}

export enum Reaction {
    VOLUNTEER = 'volunteer',
    BEST = 'best',
    GOOD = 'good',
    BAD = 'bad',
    WORST = 'worst'
}

export enum ContactType {
    ODD = 'odd',
    STREET_CUBE = 'streetCube',
    STREET_PROMO = 'streetPromo'
}

export enum MaterialDistributionStatus {
    SUCCESS = 'success',
    FAILURE = 'failure',
}
