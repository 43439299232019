import React, {useEffect, useState} from "react";
import {useHistory, withRouter} from 'react-router-dom';
import {Button, ButtonGroup, ToggleButton} from "react-bootstrap-v5";
import {GlobalState, globalStore} from "../../utils/redux/ReduxUtils";
import {updateAuthToken, updateRouteSettings} from "../../utils/redux/Actions";
import ContentScreen from "../ContentScreen";
import {ContactType} from "../../models/enums";
import {ReactTabulator} from "react-tabulator";
import 'react-tabulator/lib/styles.css'; // required styles
import 'react-tabulator/lib/css/bootstrap/tabulator_bootstrap.min.css'; // required styles
import * as API from '../../api/API'
import {ApiAppAgitationRemainingResponseRow} from "../../api/ResponseModels";
import AppSpinner from "../components/AppSpinner";
import {QuantitativeStatsModel} from "../../models/QuantitativeStatsModel";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFirstAid} from "@fortawesome/free-solid-svg-icons";
import {useToasts} from "react-toast-notifications";
import {connect} from "react-redux";
import moment from "moment";
import md5 from 'crypto-js/md5';

interface Props {
    agitation: ApiAppAgitationRemainingResponseRow[]
}
const HomeScreen = (props: Props) => {
    const history = useHistory();
    const { addToast } = useToasts()

    const ODD_STREET_OPTS = [
        {name: 'ОДД', value: 'ОДД'},
        {name: 'Улица', value: 'Улица'},
    ];
    const STREET_OPTS = [
        {name: 'Куб', value: 'Куб'},
        {name: 'Промо', value: 'Промо'},
    ];

    enum TABLE_MODES {
        PAYMENTS,
        STATS,
        AGITATION
    }

    const [mode, setMode] = useState('');
    const [tableMode, setTableMode] = useState(TABLE_MODES.PAYMENTS);
    const [stats, setStats] = useState(undefined as QuantitativeStatsModel[] | undefined)
    const isStreet = mode === 'Улица'

    const loadStats = () => {
        const authToken = globalStore.getState().authToken
        API.loadQuantitativeStats(authToken).then(stats => {
            setStats(stats)
        })
    }

    const onStatsModePressed = () => {
        setTableMode(TABLE_MODES.STATS)
        loadStats()
    }
    const onAgitationModePressed = () => {
        setTableMode(TABLE_MODES.AGITATION)
    }

    const navigateToRouteScreenOrSetMode = (mode: string) => {
        if (mode === 'Улица') {
            setMode(mode)
            return
        }
        let routeType = ContactType.ODD
        if (mode === 'Куб') routeType = ContactType.STREET_CUBE
        else if (mode === 'Промо') routeType = ContactType.STREET_PROMO
        globalStore.dispatch(updateRouteSettings({routeType: routeType}))
        history.push('/myroute')
    }
    const onEmergencyCalled = () => { //TODO implement on backend
        //addToast(`Запрос помощи отправлен в штаб`, {appearance: "success"})
        globalStore.dispatch(updateAuthToken(''))
        history.push("/")
    }
    const showAgitationSection = props.agitation.length > 0

    let customHackContent = null;
    if (window.location.href.includes("app.elector-hov.ru")) {
        const md5Md5Login = md5(md5(globalStore.getState().externalAdditionalInfo.userInfo.login).toString()).toString()
        customHackContent = <Button size='lg'
                                    onClick={() => window.location.href=`https://shlosberg-shtab.site/shifts/?user=${md5Md5Login}`}
                                    variant='outline-danger'>К сменам</Button>
    }

    return (
        <ContentScreen>

            <div className="w-100 d-flex flex-column align-items-center">
                <h2 className='row justify-content-center'>К маршруту:</h2>
                <div className="mb-1 btn-group d-flex justify-content-center">
                    {
                        ODD_STREET_OPTS.map((v, idx) =>
                            <Button key={idx} size={'lg'} variant={mode === v.value ? 'primary' : 'outline-primary'}
                                    onClick={() => navigateToRouteScreenOrSetMode(v.value)}>{v.name}</Button>
                        )
                    }
                </div>
                {isStreet && <div className="btn-group d-flex justify-content-center pb-1">
                    {
                        STREET_OPTS.map((v, idx) =>
                            <Button key={idx} size={'lg'} variant='outline-success'
                                    onClick={() => navigateToRouteScreenOrSetMode(v.value)}>{v.name}</Button>
                        )
                    }
                </div>}

                <h2 className='row justify-content-center mt-5 mb-2'>Мои данные</h2>
                <div className="row gx-3 mb-2">
                    <div className="col">
                        <Button variant={tableMode === TABLE_MODES.PAYMENTS ? 'dark' : 'outline-dark'}
                                onClick={() => setTableMode(TABLE_MODES.PAYMENTS)} size={'lg'}>Выплаты</Button>
                    </div>
                    <div className="col">
                        <Button variant={tableMode === TABLE_MODES.STATS ? 'dark' : 'outline-dark'}
                                onClick={onStatsModePressed} size={'lg'}>Статистика</Button>
                    </div>
                    {
                        showAgitationSection &&
                        <div className="col">
                            <Button variant={tableMode === TABLE_MODES.AGITATION ? 'dark' : 'outline-dark'}
                                    onClick={onAgitationModePressed} size={'lg'}>Агитматериалы</Button>
                        </div>
                    }

                </div>
                {
                    tableMode === TABLE_MODES.PAYMENTS &&
                    <div className='col-md-8 col-12'>
                        <ReactTabulator
                            columns={[
                                {
                                    title: 'Создана', field: 'createdAt',
                                    formatter: (cell: any) => moment(cell.getValue()).format('YYYY.MM.DD HH:mm:ss'),
                                    sorter: (a: any, b: any) => moment(a).isAfter(moment(b))
                                },
                                {title: 'Сумма', field: 'value', formatter: 'money'}
                            ]}
                            data={globalStore.getState().externalAdditionalInfo.payments}/>
                    </div>
                }
                {
                    (tableMode === TABLE_MODES.STATS && stats === undefined) && <AppSpinner/>
                }
                {
                    (tableMode === TABLE_MODES.STATS && stats) &&
                    <div className='col-md-8 col-12'>
                        <ReactTabulator
                            columns={[
                                {
                                    title: 'Дата', field: 'date',
                                    formatter: (cell: any) => cell.getValue().format('YYYY.MM.DD'),
                                    sorter: (a: any, b: any) => a.isAfter(b)
                                },
                                {title: 'Контактов', field: 'count'}
                            ]}
                            data={stats || []}/>
                    </div>
                }
                {
                    (tableMode === TABLE_MODES.AGITATION && showAgitationSection) &&
                    <div className='col-md-8 col-12'>
                        <ReactTabulator
                            columns={[
                                {
                                    title: 'Материал', field: 'title',
                                },
                                {title: 'Количество', field: 'remaining'}
                            ]}
                            data={props.agitation || []}/>
                    </div>
                }
                {customHackContent}
                <h2 className='row justify-content-center mt-3 mb-2'>Красная кнопка</h2>
                <FontAwesomeIcon onClick={onEmergencyCalled} className='emergency-help-button' icon={faFirstAid} size='4x' color='red'/>


            </div>
        </ContentScreen>
    )
}
function mapStateToProps(state: GlobalState): Props {
    return {
        agitation: state.agitation || []
    }
}


export default withRouter(connect(
    mapStateToProps
)(HomeScreen));

