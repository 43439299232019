import * as React from 'react'
import {Nav, Navbar, NavDropdown} from "react-bootstrap-v5";
import {faBars} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useHistory} from 'react-router-dom';
import {GlobalState, globalStore} from "../utils/redux/ReduxUtils";
import {connect} from "react-redux";
import {RouteSettings} from "../utils/redux/models";
import {updateAgitationData, updateAuthToken} from "../utils/redux/Actions";
import ImCheckSvgLogo from '../assets/icons/im-check.svg'
import {useEffect} from "react";
import * as DB from "../utils/DB";
import {UPDATE_UNSENT_COUNT} from "../utils/redux/types";
import * as API from "../api/API";
import {
    thunkOnLocalContactSuccessfullySentAction,
    thunkOnLocalMaterialDistributionSuccessfullySentAction
} from "../utils/redux/ThunkActions";
import {useToasts} from "react-toast-notifications";
import * as ApiRequestMappers from "../api/ApiRequestMappers";
import * as LocalStorage from "../utils/LocalStorage";
import moment from "moment";

interface Props {
    routeSettings?: RouteSettings
    unsentRowsCount: number
}

export const MyHeader = (props: Props) => {
    const history = useHistory();
    const onLogout = () => {
        globalStore.dispatch(updateAuthToken(''))
        history.push("/")
    }
    const {addToast} = useToasts()

    const reloadApp = (isInitialRun: boolean) => {
        let lastSyncMoment = LocalStorage.getLastSyncMoment();
        let now = moment().valueOf();
        let updateSyncDateAndReload = () => {
            LocalStorage.setLastSyncMoment(now);
            window.location.reload(true);
        };
        let clearCachesAndReload = () => {
            console.log('clearCachesAndReload')
            if ("caches" in window) { // fuck Safari
                caches.keys().then(function(cacheNames) {
                    if (cacheNames.length > 0) {
                        return Promise.all(
                            cacheNames.map(function(cacheName) {
                                return caches.delete(cacheName);
                            }).map(v => {
                                updateSyncDateAndReload();
                                return v;
                            })
                        );
                    } else {
                        updateSyncDateAndReload();
                    }
                })
            }
        };
        if (lastSyncMoment && isInitialRun) {
            let diff = now - lastSyncMoment;
            if (diff > 600 * 1000 * 60) { // 10h cache limit
                clearCachesAndReload();
            }
        } else {
            clearCachesAndReload();
        }
    }
    useEffect(() => {
        reloadApp(true)
    }, [])

    useEffect(() => {
        async function actualizeUnsentCount() {
            const unsentFromDb = await DB.getTotalUnsentRowsCount()
            globalStore.dispatch(
                {
                    type: UPDATE_UNSENT_COUNT,
                    payload: unsentFromDb
                }
            )
        }
        const syncAll = async () => {
            await actualizeUnsentCount()
            await synchronizeUnsent()
        }
        syncAll()
    }, [])
    const synchronizeUnsent = async () => {
        const handleMaterialsF = async () => {
            const unsentMaterials = await DB.getAllUnsentMaterials()
            console.log(`materials sync attempt, before ${unsentMaterials.length}`)
            const promises = await Promise.all(unsentMaterials.map(async (v) =>
                    await API.createAgitationDistribution(v, globalStore.getState().authToken)
                        .then(resp => {
                            // @ts-ignore
                            globalStore.dispatch(thunkOnLocalMaterialDistributionSuccessfullySentAction(v))
                        })
                        .catch(err => {
                            console.log(`unable to re-send material row ${JSON.stringify(v)}`, err)
                        })
                )
            )
            const unsentMaterialsAfterSync = await DB.getAllUnsentMaterials()
            console.log(`materials sync attempt, after ${unsentMaterialsAfterSync.length}`)
            return [unsentMaterials.length, unsentMaterialsAfterSync.length]
        }
        const handleContactsF = async () => {
            const unsentContacts = await DB.getAllUnsentContacts()
            console.log(`contacts sync attempt, before ${unsentContacts.length}`)
            const promises = await Promise.all(
                unsentContacts.map(async (c) =>
                    await API.createContacts(
                        {contacts: [ApiRequestMappers.mapContactToRequest(c, c.geo)]},
                        globalStore.getState().authToken
                    ).then(resp => {
                        //@ts-ignore
                        globalStore.dispatch(thunkOnLocalContactSuccessfullySentAction(c))
                    }).catch(err => {
                        console.log(`unable to re-send contact row ${JSON.stringify(c)}`, err)
                    })
                )
            )
            const unsentContactsAfterSync = await DB.getAllUnsentContacts()
            console.log(`contacts sync attempt, after ${unsentContactsAfterSync.length}`)
            return [unsentContacts.length, unsentContactsAfterSync.length]
        }
        await handleMaterialsF().then(async materialsDiff => {
            const beforeMats = materialsDiff[0]
            const afterMats = materialsDiff[1]
            if (beforeMats !== 0) {
                if (afterMats === 0) addToast('Разноски успешно выгружены', {appearance: 'success'})
                else if (afterMats === beforeMats) {
                    addToast('Не удалось выгрузить разноски', {appearance: 'error'})
                } else {
                    addToast(
                        `Разноски выгружены частично. Выгружены: ${beforeMats - afterMats}, не выгружены ${afterMats}`,
                        {appearance: 'warning'}
                    )
                }
                const response = await API.getMyAgitationMaterials(globalStore.getState().authToken)
                globalStore.dispatch(updateAgitationData(response.rows))
            }
        }).catch(err => {
            if (err.message === 'NetworkError when attempting to fetch resource.') {
                addToast('Нет связи с сервером', {appearance: 'error'})
            } else {
                addToast('Неизвестная ошибка при попытке синхронизации данных', {appearance: 'error'})
                console.log('Неизвестная ошибка при попытке синхронизации данных', err)
            }
        })

        await handleContactsF().then(async contactsDiff => {
            const beforeContacts = contactsDiff[0]
            const afterContacts = contactsDiff[1]
            if (beforeContacts !== 0) {
                if (afterContacts === 0) addToast('Контакты успешно выгружены', {appearance: 'success'})
                else if (afterContacts === beforeContacts) {
                    addToast('Не удалось выгрузить контакты', {appearance: 'error'})
                } else {
                    addToast(
                        `Контакты выгружены частично. Выгружены: ${beforeContacts - afterContacts}, не выгружены ${afterContacts}`,
                        {appearance: 'warning'}
                    )
                }
                const response = await API.getMyAgitationMaterials(globalStore.getState().authToken)
                globalStore.dispatch(updateAgitationData(response.rows))
            }
        }).catch(err => {
            if (err.message === 'NetworkError when attempting to fetch resource.') {
                addToast('Нет связи с сервером', {appearance: 'error'})
            } else {
                addToast('Неизвестная ошибка при попытке синхронизации данных', {appearance: 'error'})
                console.log('Неизвестная ошибка при попытке синхронизации данных', err)
            }
        })


    }

    return (
        <header className="fixed-top">
            <Navbar expand="lg"
                    className={'navbar navbar-expand-md navbar-dark bg-dark position-absolute w-100 shadow-sm'}>
                <NavDropdown
                    title={<FontAwesomeIcon color='white' icon={faBars}/>}
                    className='ms-lg-5'
                    id="basic-nav-dropdown">
                    {props.routeSettings &&
                    <>
                        <NavDropdown.Divider/>
                        <NavDropdown.Item onClick={() => history.push("/myroute")}>Мой маршрут</NavDropdown.Item>
                    </>
                    }
                    <NavDropdown.Item onClick={() => history.push("/history")}>История</NavDropdown.Item>
                    <NavDropdown.Divider/>
                    <NavDropdown.Item onClick={() => history.push("/communication")}>Связь со штабом</NavDropdown.Item>
                    <NavDropdown.Divider/>
                    <NavDropdown.Item onClick={synchronizeUnsent}>Синхронизация</NavDropdown.Item>
                    <NavDropdown.Item onClick={() => reloadApp(false)}>Обновить</NavDropdown.Item>
                    <NavDropdown.Item onClick={onLogout}>Выйти</NavDropdown.Item>
                </NavDropdown>
                <Nav.Link onClick={() => history.push("/home")}>
                    <h5 className="mr-1 link-light mb-1 d-flex flex-row align-items-end">
                        Электор
                        <img className='align-self-start' style={{width: '16px'}} src={ImCheckSvgLogo}/>
                    </h5>
                </Nav.Link>
                {
                    Boolean(props.unsentRowsCount) &&
                    <Nav.Link
                        onClick={synchronizeUnsent}
                        className={'ms-auto text-white'}
                        style={{paddingTop: '0.3rem'}}>
                        {`Не выгружено: `}
                        <span className='text-red'>{props.unsentRowsCount}</span>
                    </Nav.Link>
                }
                <Nav className={Boolean(props.unsentRowsCount) ? '' : 'ms-auto'}>
                    <Nav.Link className="me-1 me-lg-5 gx-3 row" onClick={() => history.push("/profile")}>
                        <span>{globalStore.getState().externalAdditionalInfo.userInfo.name}</span>
                        {
                            <img className="bg-dark rounded-circle avatar avatar-md"
                                 src="https://img.icons8.com/color/64/000000/test-account.png"/>
                        }

                    </Nav.Link>
                </Nav>
            </Navbar>
        </header>
    )
}


const ContentScreen = (props: React.PropsWithChildren<Props>) => {
    return ( // после хедера отступы потому что он fixed
        <div className="App container-fluid">
            <MyHeader unsentRowsCount={props.unsentRowsCount} routeSettings={props.routeSettings}/>
            <div className="mt-5 mb-4 pb-2"/>
            <div className="d-flex flex-column align-items-center w-100 mt-lg-5">
                {props.children}
            </div>
        </div>
    )
}

function mapStateToProps(state: GlobalState): Props {
    return {
        routeSettings: state.routeSettings,
        unsentRowsCount: state.unsentRowsCount
    }
}

export default connect(
    mapStateToProps
)(ContentScreen);
