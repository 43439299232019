import * as ApiResponseMappers from './ApiResponseMappers'
import {AddressModel} from "../models/ExternalAdditionalInfo";
import {API_HOST} from "../utils/Consts";
import {APIAddressesResponse, APIAuthResponse, APIExternalAdditionalInfo, APIUserInfo} from "./Models";
import {
    ApiAppCreateAgitationGeoDistributionRequest,
    ApiContactsRequestModel,
    UpdateUserInfoRequest
} from "./RequestModels";
import {
    ApiAppAgitationRemainingResponse,
    ApiAppHouseEntranceRecentContactsResponse,
    ApiContactInfoResponse,
    ApiContactInfoResponseRow,
    ContactsResponse,
    QuantitativeStatsResponse,
    QuantitativeStatsResponseRow
} from "./ResponseModels";
import {ContactModel} from "../models/ContactModel";
import {globalStore} from "../utils/redux/ReduxUtils";
import {updateAuthToken} from "../utils/redux/Actions";
import {QuantitativeStatsModel} from "../models/QuantitativeStatsModel";
import moment from "moment";

export const authorize = (login: string, password: string) => {
    return requestPostAPI<APIAuthResponse>(API_HOST + "/app/authorize", JSON.stringify({
        login: login,
        password: password
    }))
}

export async function loadAdditionalInfo(authToken: string): Promise<APIExternalAdditionalInfo> {
    return await requestGetAPI<APIExternalAdditionalInfo>(API_HOST + "/common/loadGeneralInfo", authToken)
}

export async function checkHealth(authToken: string): Promise<any> {
    return await requestGetAPI<any>(API_HOST + "/common/health", authToken)
}

export async function updateUserInfo(req: UpdateUserInfoRequest, authToken: string): Promise<APIUserInfo> {
    return await requestPostAPI<APIUserInfo>(API_HOST + "/common/updateUserInfo", JSON.stringify(req), authToken)
}

export async function loadAddressesInfo(authToken: string): Promise<AddressModel[]> {
    const resp = await requestGetAPI<APIAddressesResponse>(API_HOST + '/app/getAddressesInfo', authToken)
    return resp.rows
}

export async function getMyAgitationMaterials(authToken: string): Promise<ApiAppAgitationRemainingResponse> {
    return await requestGetAPI<ApiAppAgitationRemainingResponse>(API_HOST + '/app/agitation-materials/me', authToken)
}

export async function createContacts(req: ApiContactsRequestModel, authToken: string): Promise<any> {
    return await requestPostAPI<any>(API_HOST + "/app/contacts", JSON.stringify(req), authToken)
}

export async function createAgitationDistribution(req: ApiAppCreateAgitationGeoDistributionRequest, authToken: string): Promise<ApiAppAgitationRemainingResponse> {
    return await requestPostAPI<ApiAppAgitationRemainingResponse>(API_HOST + "/app/agitation-materials", JSON.stringify(req), authToken)
}

export async function getContacts(authToken: string): Promise<ApiContactInfoResponseRow[]> {
    const contactsApi = await requestGetAPI<ApiContactInfoResponse>(API_HOST + "/app/contacts", authToken)
    return contactsApi.rows
}
export async function getRecentContactsForHouseAndEntrance(houseId: number, entranceNo: number, authToken: string): Promise<ApiAppHouseEntranceRecentContactsResponse> {
    return await requestGetAPI<ApiAppHouseEntranceRecentContactsResponse>(API_HOST + `/app/contacts/latest?houseId=${houseId}&entranceNo=${entranceNo}`, authToken)
}

export async function getContactById(id: number, authToken: string): Promise<ApiContactInfoResponseRow> {
    return await requestGetAPI<ApiContactInfoResponseRow>(API_HOST + `/app/contact/${id}`, authToken)
}

export async function loadQuantitativeStats(authToken: string): Promise<QuantitativeStatsModel[]> {
    const statsResponse = await requestGetAPI<QuantitativeStatsResponse>(API_HOST + `/app/stats`, authToken)
    return statsResponse.rows.map(row => ({date: moment(row.date), count: row.count}))
}

export async function deleteContact(contactId: number, authToken: string): Promise<any> {
    return await requestDeleteAPI(API_HOST + `/app/contact/${contactId}`, authToken)
}

// Implementation code where T is the returned data shape
function requestGetAPI<T>(url: string, authToken: string): Promise<T> {
    console.log("making GET req to " + url);
    return fetch(url, {headers: new Headers({'Authorization': authToken})})
        .then(response => commonResponseHandler(response))
}

function requestDeleteAPI<T>(url: string, authToken: string): Promise<T> {
    console.log("making DELETE req to " + url);
    return fetch(url, {
        headers: new Headers({'Authorization': authToken}),
        method: 'DELETE'
    })
        .then(response => {
            if (!response.ok) {
                throw new Error(response.statusText)
            }
            return response.json() as Promise<T>
        })
}

function requestPostAPI<T>(url: string, body: string, authTokenOpt?: string): Promise<T> {
    console.log(`making POST req to ${url} body ${body}`);
    return fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': authTokenOpt || ''
        },
        body: body
    }).then(response => {
        return response.json() as Promise<T>;
    });
}

function commonResponseHandler<T>(response: Response): Promise<T> {
    if (response.status === 401) {
        globalStore.dispatch(updateAuthToken(''))
    } else if (!response.ok) {
        return Promise.reject(new Error(response.statusText))
    }
    return response.json() as Promise<T>;
}
