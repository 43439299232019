import {AddressModel} from "../../models/ExternalAdditionalInfo";
import {RouteSettings} from "./models";
import {ApiAppAgitationRemainingResponseRow} from "../../api/ResponseModels";
import {APIExternalAdditionalInfo, APIUserInfo} from "../../api/Models";

export const UPDATE_LOADED_DATA = 'UPDATE_LOADED_DATA'
export const UPDATE_AGITATION_DATA = 'UPDATE_AGITATION_DATA'
export const UPDATE_USER_INFO = 'UPDATE_USER_INFO'
export const UPDATE_ROUTE_SETTINGS = 'UPDATE_ROUTE_SETTINGS'
export const UPDATE_AUTH_TOKEN = 'UPDATE_AUTH_TOKEN'
export const UPDATE_UNSENT_COUNT = 'UPDATE_UNSENT_COUNT'

export interface LoadedDataUpdate {
    info: APIExternalAdditionalInfo
    addresses: AddressModel[]
    agitation: ApiAppAgitationRemainingResponseRow[]
}

export interface UpdateLoadedDataAction {
    type: typeof UPDATE_LOADED_DATA
    payload: LoadedDataUpdate
}

export interface UpdateAuthTokenAction {
    type: typeof UPDATE_AUTH_TOKEN
    payload: string
}

export interface UpdateUserInfoAction {
    type: typeof UPDATE_USER_INFO
    payload: APIUserInfo
}

export interface UpdateRouteSettingsAction {
    type: typeof UPDATE_ROUTE_SETTINGS
    payload: RouteSettings
}

export interface UpdateAgitationDataAction {
    type: typeof UPDATE_AGITATION_DATA
    payload: ApiAppAgitationRemainingResponseRow[]
}

export interface UpdateUnsentDataCountAction {
    type: typeof UPDATE_UNSENT_COUNT
    payload: number
}

export type ReducerAction =
    UpdateLoadedDataAction
    | UpdateUserInfoAction
    | UpdateRouteSettingsAction
    | UpdateAuthTokenAction
    | UpdateAgitationDataAction
    | UpdateUnsentDataCountAction

