import React from "react";
import TableSearchFilterView, {FilterOption} from "./TableSearchFilterView";
import {AddressModel} from "../../../models/ExternalAdditionalInfo";
import {globalStore} from "../../../utils/redux/ReduxUtils";

interface Props {
    value?: FilterOption<number, string>
    setValue: (v: FilterOption<number, string> | undefined) => void
}
export default class AddressSearchTableFilter extends React.Component<Props, any> {
    searchRows = async (searchQueryOpt?: string) => {
        const allAddresses: AddressModel[] = globalStore.getState().addresses;
        let filteredAddresses: AddressModel[] = []

        const trimmedQuery = searchQueryOpt? searchQueryOpt.trim().toLowerCase(): ''
        if (searchQueryOpt && trimmedQuery) {
            filteredAddresses = allAddresses.filter(addr =>
                addr.address.toLowerCase().includes(trimmedQuery)
            )
        } else filteredAddresses = allAddresses

        return filteredAddresses.map(r => ({
            key: r.id,
            value: r.address,
            label: r.address
        }))
    }

    render() {
        return (
            <TableSearchFilterView
                title={'Выберите адрес...'}
                value={this.props.value}
                setValue={this.props.setValue}
                searchRows={this.searchRows}
            />
        )
    }
}
