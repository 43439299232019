import {globalStore} from "../../utils/redux/ReduxUtils";
import * as React from 'react'
import {PropsWithChildren, useState} from 'react'
import './SelectSearch.scss'
import './MyRouteScreen.scss'
import classnames from "classnames";
import {Age, Gender, Reaction} from "../../models/enums";
import {Button} from "react-bootstrap-v5";
import {useToasts} from "react-toast-notifications";
import {faUserPlus, faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ContactModel} from "../../models/ContactModel";
import * as TypedUtils from '../../utils/TypedUtils'
import {ApiAppAgitationRemainingResponseRow, ApiContactInfoResponseRow} from "../../api/ResponseModels";
import {AgitationGiveSection} from "../components/route/AgitationGiveSection";
import PhoneInput from "react-phone-input-2";
import {isValidPhoneNumber} from "libphonenumber-js";
import Icon from "@iconify/react";
import check16 from "@iconify/icons-octicon/check-16";
import pencil24 from "@iconify/icons-octicon/pencil-24";
import {commonDateTimeFormat} from "../../utils/TypedUtils";
import moment from "moment";
import {KeyboardDateTimePicker} from '@material-ui/pickers';
import {ApiContactGeo} from "../../api/RequestModels";
import {ContactGeoType} from "../../api/Models";
import AddressEditModal from "./contacts/AddressEditModal";


interface Props {
    onSaveContact: () => Promise<any>,
    onAddContactPressed?: () => any, //undefined if editing existing - shouldn't be able to add
    onCancelPressed: () => any,
    onContactDelete: () => any,
    onContactEdited: (contact: ContactModel) => any,
    onNextArrowPressed: () => any,
    onBackArrowPressed: () => any,
    contact: ContactModel,
    rawApiContact?: ApiContactInfoResponseRow,
    contactsCount: number,
    contactNo: number
    agitation: ApiAppAgitationRemainingResponseRow[]
}

const ContactInput = (props: PropsWithChildren<Props>) => {
    const {addToast} = useToasts()

    const THEMATICS = globalStore.getState().externalAdditionalInfo.thematics
    const globalCommonSettings = globalStore.getState().externalAdditionalInfo.commonSettings

    function changeContactProp<T>(propName: string, value: T) {
        // @ts-ignore
        const contactCopy = {...props.contact}
        // @ts-ignore
        contactCopy[propName] = value
        props.onContactEdited && props.onContactEdited(contactCopy)
    }

    const ALL_THEMATICS = Array.from(new Set(THEMATICS.concat(props.contact.thematics)))

    const setName = (n: string) => changeContactProp('name', n)
    const setSurname = (n: string) => changeContactProp('surname', n)
    const setPatronymic = (n: string) => changeContactProp('patronymic', n)
    const setGender = (n: Gender) => changeContactProp('gender', n)
    const setReaction = (n: Reaction) => changeContactProp('reaction', n)
    const toggleThematics = (n: string) => {
        if (globalCommonSettings.allowMultiThematicsSelect) {
            const newThematics = props.contact.thematics.includes(n)
                ? props.contact.thematics.filter(t => t != n)
                : ALL_THEMATICS.filter(t => props.contact.thematics.concat([n]).includes(t)) // preserve order
            if (newThematics.length > 0) changeContactProp('thematics', newThematics)
        } else changeContactProp('thematics', [n])
        // changeContactProp('thematics', n)
    }
    const setEmail = (n: string) => changeContactProp('email', n)
    const setPhoneNumber = (n: string) => changeContactProp('phoneNumber', n)
    const setComment = (n: string) => changeContactProp('comment', n)
    const setAge = (n: Age) => changeContactProp('age', n)
    const setContactCreatedAt = (m?: moment.Moment) => changeContactProp('createdAt', m)

    const [error, setError] = useState('')
    const [isSaving, setIsSaving] = useState(false)

    const onAddContactPressed = () => {
        const trimmedPhoneNumber = props.contact.phoneNumber?.trim()
        if (trimmedPhoneNumber && !isValidPhoneNumber('+' + trimmedPhoneNumber)) {
            setError('Пожалуйста, укажите правильный телефонный номер')
        } else if (props.contact.name && props.onAddContactPressed) props.onAddContactPressed()
        else setError('Пожалуйста, заполните обязательные поля')
    }
    const onSaveContactsPressed = () => {
        const trimmedPhoneNumber = props.contact.phoneNumber?.trim()
        if ((trimmedPhoneNumber && !isValidPhoneNumber('+' + trimmedPhoneNumber)) || (!trimmedPhoneNumber && !globalCommonSettings.allowContactsCreationWithoutPhoneNumber)) {
            setError('Пожалуйста, укажите правильный телефонный номер')
        } else if (props.contact.name && props.onSaveContact) {
            const isCurrentlySaving = isSaving
            setIsSaving(true)
            if (!isCurrentlySaving) {
                props.onSaveContact().finally(() => setIsSaving(false))
            }
        }
        else setError('Пожалуйста, заполните обязательные поля')
    }
    const nonEmptyAgitations = props.agitation.filter(a => a.remaining > 0)
    const [showAgitationSection, setShowAgitationSection] = useState(false)
    const [showAddressEditSection, setShowAddressEditSection] = useState(false)

    const showCreatedSection = globalCommonSettings.allowContactCreatedAtEditForScouts || Boolean(props.rawApiContact)

    const toggleContactCreatedAtInput = () => {
        props.contact.createdAt ? setContactCreatedAt(undefined) : setContactCreatedAt(moment())
    }
    return (
        <main id="contact" className="container-fluid">
            <div className="max-w-md shadow-lg mx-auto mb-4">
                {
                    nonEmptyAgitations.length > 0 &&
                    <div className='d-flex justify-content-end me-3'>
                        <a className='clickable-href' onClick={() => setShowAgitationSection(!showAgitationSection)}>
                            Показать агитацию
                        </a>
                    </div>
                }
                {
                    showAgitationSection &&
                    <div className='mb-2'>
                        <AgitationGiveSection
                            houseId={props.contact.geo?.addressId}
                            entranceNo={props.contact.geo?.entranceNo}
                            agitation={props.agitation}/>
                    </div>

                }

                <div
                    className="bg-dark text-white text-center fw-500 py-3 d-flex flex-row justify-content-between align-items-center">
                            <span className='ms-5'>
                                {
                                    (props.contactsCount > 1 && props.contactNo != 0) &&
                                    <FontAwesomeIcon style={{cursor: 'pointer'}}
                                                     onClick={props.onBackArrowPressed} className='me-2' color='gold'
                                                     icon={faChevronLeft}/>
                                }
                                Карточка контакта
                                {
                                    props.contact.id !== 0 &&
                                    <span className="font-monospace fw-bold text-red ms-1">{props.contact.id}</span>
                                }
                                {
                                    props.contactsCount > 1 &&
                                    <>
                                        <span
                                            style={{color: props.contactNo === props.contactsCount ? 'red' : 'white'}}
                                            className='ms-1'>{`${props.contactNo + 1}`}
                                        </span>
                                        /
                                        <span style={{color: 'red'}}>{`${props.contactsCount}`}</span>
                                    </>
                                }
                                {
                                    (props.contactsCount > 1 && props.contactNo != props.contactsCount - 1) &&
                                    <FontAwesomeIcon style={{cursor: 'pointer'}}
                                                     onClick={props.onNextArrowPressed} className='ms-2' color='gold'
                                                     icon={faChevronRight}/>
                                }

                            </span>

                    {
                        props.onAddContactPressed &&
                        <span className='add-more-contact me-2' onClick={onAddContactPressed}>
                                Ещё
                                <FontAwesomeIcon className='ms-1' color='gold' icon={faUserPlus}/>
                            </span>
                    }

                </div>
                <div id="contact-form">
                    <fieldset id="contact-fieldset">
                        <div className="bg-white p-4 pt-1">
                            {
                                error && <div className='mb-3 font-monospace fw-bold text-red text-center'>{error}</div>
                            }
                            {
                                showCreatedSection &&
                                <div className="row small">
                                    <div className="col-3 fw-500">Создан</div>
                                    <a className="col-9 cursor-pointer">
                                        {
                                            props.rawApiContact
                                                ? <span
                                                    className={'font-monospace'}>{commonDateTimeFormat(moment(props.rawApiContact?.createdAt))}</span>
                                                : Boolean(props.contact.createdAt)
                                                ? <KeyboardDateTimePicker
                                                    ampm={false}
                                                    value={props.contact.createdAt}
                                                    onChange={d => d ? setContactCreatedAt(d) : setContactCreatedAt(undefined)}
                                                    label="Дата создания"
                                                    onError={console.log}
                                                    minDate={new Date("2000-01-01T00:00")}
                                                    format="DD.MM.YYYY HH:mm"
                                                    size={'small'}
                                                    inputVariant={'outlined'}
                                                />
                                                : <div className=''
                                                       onClick={() => toggleContactCreatedAtInput()}>сейчас
                                                    <Icon className='mx-1' icon={pencil24} width={16}
                                                          color={'#206bc4'}/>
                                                </div>

                                        }
                                    </a>
                                </div>
                            }
                            <div className="row small border-bottom pb-1 mb-1">
                                <div className="col-3 fw-500 mb-1">Адрес</div>
                                <div className="col-9 d-flex flex-row">
                                    <div className={'font-monospace'}>
                                        {
                                            props.contact.fullAddress
                                                ? TypedUtils.makeFullAddressWithGeo(props.contact.fullAddress, props.contact.geo)
                                                : 'Не указан'
                                        }
                                    </div>
                                    <div className='cursor-pointer'
                                         onClick={() => setShowAddressEditSection(true)}>
                                        <Icon className='mx-1' icon={pencil24} width={16}
                                              color={'#206bc4'}/>
                                    </div>
                                </div>
                                {
                                    showAddressEditSection &&
                                    <AddressEditModal visible={showAddressEditSection}
                                                      onSave={s => {
                                                          const nextGeo: ApiContactGeo | undefined = s ? {
                                                              addressId: s.addressId,
                                                              flatNo: s.flatNo,
                                                              entranceNo: s.entranceNo,
                                                              type: ContactGeoType.HOUSE_REQUEST_GEO
                                                          } : undefined
                                                          const contactCopy: ContactModel = {...props.contact}
                                                          contactCopy.geo = nextGeo
                                                          contactCopy.fullAddress = s?.fullAddress
                                                          props.onContactEdited && props.onContactEdited(contactCopy)
                                                          setShowAddressEditSection(false)
                                                      }}
                                                      initialValue={
                                                          (props.contact.geo && props.contact?.fullAddress) ?
                                                              {
                                                                  geo: props.contact.geo,
                                                                  fullAddress: props.contact?.fullAddress
                                                              } : undefined
                                                      }
                                                      toggleVisibility={() => setShowAddressEditSection(false)}/>
                                }

                            </div>
                            <div className="row">
                                <label htmlFor="name" className="col-form-label col-3 form-label-required">Имя</label>
                                <div className="col-9 mb-3">
                                    <input id="name" className="form-control"
                                           value={props.contact.name}
                                           placeholder="Мария"
                                           type="name" onChange={e => setName(e.target.value)}/>
                                </div>
                                {/* -------------------------------------------------- */}
                                <label htmlFor="surname" className="col-form-label col-3">Фамилия</label>
                                <div className="col-9 mb-3">
                                    <input id="surname" className="form-control pr-0"
                                           value={props.contact.surname || ''}
                                           placeholder="Иванова" type="name"
                                           onChange={e => setSurname(e.target.value)}/>
                                </div>
                                {/* -------------------------------------------------- */}
                                <label htmlFor="fathername" className="col-form-label col-3">Отчество</label>
                                <div className="col-9 mb-3">
                                    <input id="fathername" className="form-control"
                                           value={props.contact.patronymic || ''}
                                           placeholder="—" type="name"
                                           onChange={e => setPatronymic(e.target.value)}/>
                                </div>
                                {/* -------------------------------------------------- */}
                                <label className="col-form-label col-3">Пол</label>
                                <div className="col-9 mb-3">
                                    <div className="btn-group w-100">
                                        <input id="male" className="btn-check" type="radio" name="gender"
                                               checked={props.contact.gender === Gender.MALE}
                                               onClick={() => setGender(Gender.MALE)}/>
                                        <label htmlFor="male" className="btn btn-outline-azure">Мужчина</label>
                                        <input id="female" className="btn-check" type="radio" name="gender"
                                               checked={props.contact.gender === Gender.FEMALE}
                                               onClick={() => setGender(Gender.FEMALE)}/>
                                        <label htmlFor="female" className="btn btn-outline-pink">Женщина</label>
                                    </div>
                                </div>
                                {/* -------------------------------------------------- */}
                                <label className="col-form-label col-3">Возраст</label>
                                <div className="col-9 mt-1">
                                    <div className="btn-group w-100">
                                        <input className="btn-check" type="radio" name="age" id="young"
                                               checked={props.contact.age === Age.YOUNG}
                                               onClick={() => setAge(Age.YOUNG)}/>
                                        <label htmlFor="young"
                                               className="col-4 btn btn-outline-purple btn-sm">{TypedUtils.getAgeDisplayName(Age.YOUNG)}</label>
                                        <input className="btn-check" type="radio" name="age" id="preadult"
                                               checked={props.contact.age === Age.PREADULT}
                                               onClick={() => setAge(Age.PREADULT)}/>
                                        <label htmlFor="preadult"
                                               className="col-4 btn btn-outline-purple btn-sm">{TypedUtils.getAgeDisplayName(Age.PREADULT)}</label>
                                        <input className="btn-check" type="radio" name="age" id="adult"
                                               checked={props.contact.age === Age.ADULT}
                                               onClick={() => setAge(Age.ADULT)}/>
                                        <label htmlFor="adult"
                                               className="col-4 btn btn-outline-purple btn-sm">{TypedUtils.getAgeDisplayName(Age.ADULT)}</label>
                                        <input className="btn-check" type="radio" name="age" id="elder"
                                               checked={props.contact.age === Age.ELDER}
                                               onClick={() => setAge(Age.ELDER)}/>
                                        <label htmlFor="elder"
                                               className="col-4 btn btn-outline-purple btn-sm">{TypedUtils.getAgeDisplayName(Age.ELDER)}</label>
                                        <input className="btn-check" type="radio" name="age" id='unknown'
                                               checked={props.contact.age === Age.UNKNOWN}
                                               onClick={() => setAge(Age.UNKNOWN)}/>
                                        <label htmlFor="unknown"
                                               className="col-4 btn btn-outline-purple btn-sm">{TypedUtils.getAgeDisplayName(Age.UNKNOWN, false)}</label>
                                    </div>
                                </div>
                                {/* -------------------------------------------------- */}
                                <div>
                                    <hr className="mx-n4"/>
                                </div>
                                <label htmlFor="phone" className={classnames("col-form-label col-3", globalCommonSettings.allowContactsCreationWithoutPhoneNumber? undefined: 'form-label-required')}>Телефон</label>
                                <div className="col-9 mb-3">
                                    <PhoneInput
                                        placeholder="—"
                                        value={props.contact.phoneNumber || ''}
                                        onChange={setPhoneNumber}/>
                                </div>
                                {/* -------------------------------------------------- */}
                                <label htmlFor="email" className="col-form-label col-3">Почта</label>
                                <div className="col-9 mb-3">
                                    <input id="email" className="form-control font-monospace pr-0"
                                           placeholder="—"
                                           type="email"
                                           value={props.contact.email || ''}
                                           onChange={e => setEmail(e.target.value)}/>
                                </div>

                                <div>
                                    <hr className="mx-n4"/>
                                </div>
                                {/* -------------------------------------------------- */}
                                <label className="form-label col-12 mb-3">Реакция и проблематика</label>
                                <div className="col-6 mb-3">
                                    <div className="btn-group-vertical w-100">
                                        <input id="volunteer" className="btn-check" type="radio" name="category"
                                               checked={props.contact.reaction === Reaction.VOLUNTEER}
                                               onClick={() => setReaction(Reaction.VOLUNTEER)}/>
                                        <label htmlFor="volunteer" className="btn btn-outline-green rounded-top">
                                            Волонтёр
                                        </label>
                                        <input id="best" className="btn-check" type="radio" name="category"
                                               checked={props.contact.reaction === Reaction.BEST}
                                               onClick={() => setReaction(Reaction.BEST)}/>
                                        <label htmlFor="best" className="btn btn-outline-azure rounded-top">
                                            Cторонник
                                        </label>
                                        <input id="good" className="btn-check" type="radio" name="category"
                                               checked={props.contact.reaction === Reaction.GOOD}
                                               onClick={() => setReaction(Reaction.GOOD)}/>
                                        <label htmlFor="good" className="btn btn-outline-teal">
                                            Выслушал
                                        </label>
                                        <input id="bad" className="btn-check" type="radio" name="category"
                                               checked={props.contact.reaction === Reaction.BAD}
                                               onClick={() => setReaction(Reaction.BAD)}/>
                                        <label htmlFor="bad" className="btn btn-outline-orange">
                                            Негатив
                                        </label>
                                        <input id="worst" className="btn-check" type="radio" name="category"
                                               checked={props.contact.reaction === Reaction.WORST}
                                               onClick={() => setReaction(Reaction.WORST)}/>
                                        <label htmlFor="worst" className="btn btn-outline-red">
                                            Неадекват
                                        </label>
                                    </div>
                                </div>
                                <div className="col-6 mb-3">
                                    <div className="btn-group-vertical w-100">
                                        {
                                            ALL_THEMATICS.map((t: string, idx: number) => {
                                                const isChecked = props.contact.thematics.includes(t)
                                                return (
                                                    <Button key={idx} size={'lg'}
                                                            className='d-flex align-items-center justify-content-center'
                                                            variant={isChecked ? 'indigo' : 'outline-indigo'}
                                                            onClick={() => toggleThematics(t)}>
                                                        {
                                                            isChecked &&
                                                            <Icon className='me-1' icon={check16} width={24}
                                                                  color={'white'}/>
                                                        }
                                                        {t}
                                                    </Button>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                {/* -------------------------------------------------- */}
                                <div>
                                    <hr className="mx-n4 d-none"/>
                                </div>
                                <div className="col-12">
                                    <label htmlFor="сomment" className="form-label mb-3">Комментарий</label>
                                    <label htmlFor="сomment" className="float-end text-muted"><small><span
                                        id="comment-count">{props.contact.comment?.length || 0}</span> /
                                        1000</small></label>
                                    <textarea id="сomment" className="form-control mb-"
                                              style={{minHeight: '10em', maxHeight: '30em'}} placeholder="—"
                                              value={props.contact.comment || ''}
                                              onChange={e => setComment(e.currentTarget.value)}
                                              maxLength={1000} defaultValue={""}/>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                    <div className="bg-light text-center sticky-bottom border-top p-4">
                        <div className="row gx-4">
                            <div className="col-6">
                                <button id="cancel" onClick={props.onCancelPressed}
                                        className="btn btn-danger w-100 fw-500">Отмена
                                </button>
                            </div>
                            <div className="col-6">
                                <button disabled={isSaving}
                                        onClick={onSaveContactsPressed} id="save"
                                        className="btn btn-success w-100 fw-500">Сохранить
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default ContactInput
