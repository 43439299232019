import {ThunkAction} from "@reduxjs/toolkit";
import {GlobalState} from "./ReduxUtils";
import {Action} from 'redux'
import {AddressModel} from "../../models/ExternalAdditionalInfo";
import {
    UPDATE_LOADED_DATA,
    UPDATE_UNSENT_COUNT
} from "./types";
import * as DB from '../DB'
import {ApiAppAgitationRemainingResponseRow} from "../../api/ResponseModels";
import {APIExternalAdditionalInfo, APIUserInfo} from "../../api/Models";
import {AgitationDistributionStorable, ContactStorable} from "../../models/DbStorableModels";

export const thunkUpdateLoadedDataAction = (
    info: APIExternalAdditionalInfo,
    addresses: AddressModel[],
    agitation: ApiAppAgitationRemainingResponseRow[]
): ThunkAction<void, GlobalState, unknown, Action<string>> => async dispatch => {
    const data = {
        info: info,
        addresses: addresses,
        agitation: agitation
    }
    const globalSettingsFromDb = await DB.upsertGlobalSettings(data)
    dispatch(
        {
            type: UPDATE_LOADED_DATA,
            payload: globalSettingsFromDb
        }
    )
    return globalSettingsFromDb
}

export const thunkUpdateUserInfoAction = (
    data: APIUserInfo
): ThunkAction<void, GlobalState, unknown, Action<string>> => async dispatch => {
    const globalSettingsFromDbCopy = {...await DB.getGlobalSettings()}
    globalSettingsFromDbCopy.info.userInfo = data
    dispatch(
        {
            type: UPDATE_LOADED_DATA,
            payload: globalSettingsFromDbCopy
        }
    )
    return globalSettingsFromDbCopy
}


export const thunkSaveLocalMaterialDistributionAction = (
    data: AgitationDistributionStorable
): ThunkAction<void, GlobalState, unknown, Action<string>> => async dispatch => {
    await DB.insertMaterialDistributionRow(data)
    const totalUnsent = await DB.getTotalUnsentRowsCount()

    dispatch(
        {
            type: UPDATE_UNSENT_COUNT,
            payload: totalUnsent
        }
    )
    return totalUnsent
}

export const thunkOnLocalMaterialDistributionSuccessfullySentAction = (
    sentData: AgitationDistributionStorable,
): ThunkAction<void, GlobalState, unknown, Action<string>> => async dispatch => {
    await DB.deleteUnsentMaterialRow(sentData.localUUID)
    const totalUnsent = await DB.getTotalUnsentRowsCount()
    dispatch(
        {
            type: UPDATE_UNSENT_COUNT,
            payload: totalUnsent
        }
    )
    return totalUnsent
}

export const thunkSaveLocalContactsAction = (
    contacts: ContactStorable[]
): ThunkAction<void, GlobalState, unknown, Action<string>> => async dispatch => {
    await Promise.all(
        contacts.map(c => DB.insertUnsentContactRow(c))
    )
    const totalUnsent = await DB.getTotalUnsentRowsCount()

    dispatch(
        {
            type: UPDATE_UNSENT_COUNT,
            payload: totalUnsent
        }
    )
    return totalUnsent
}
export const thunkOnLocalContactSuccessfullySentAction = (
    sentData: ContactStorable,
): ThunkAction<void, GlobalState, unknown, Action<string>> => async dispatch => {
    await DB.deleteUnsentContactRow(sentData.localUUID)
    const totalUnsent = await DB.getTotalUnsentRowsCount()
    dispatch(
        {
            type: UPDATE_UNSENT_COUNT,
            payload: totalUnsent
        }
    )
    return totalUnsent
}
