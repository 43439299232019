import React, {PropsWithChildren, useEffect, useState} from 'react';
import './elector.scss'
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import {GlobalState, globalStore} from "./utils/redux/ReduxUtils";
import AuthScreen from "./screens/AuthScreen";
import HomeScreen from "./screens/inner/HomeScreen";
import ProfileScreen from "./screens/inner/ProfileScreen";
import * as API from "./api/API";
import {updateAuthToken, updateLoadedDataAction} from "./utils/redux/Actions";
import MyRouteScreen from "./screens/inner/MyRouteScreen";
import {ToastProvider, useToasts} from 'react-toast-notifications'
import {connect} from "react-redux";
import HistoryScreen from "./screens/inner/HistoryScreen";
import AppSpinner from "./screens/components/AppSpinner";
import {thunkUpdateLoadedDataAction} from "./utils/redux/ThunkActions";
import * as DB from "./utils/DB"
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import 'moment/locale/ru'
import moment from "moment";
import {HqCommunicationScreen} from "./screens/inner/HqCommunicationScreen"; // without this line it didn't work

interface Props {
    authToken: string
}

const App = (props: PropsWithChildren<Props>) => {
    const isLoggedIn = Boolean(props.authToken);
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        moment.locale('ru')
        async function loadData() {
            const additionalInfo = await API.loadAdditionalInfo(props.authToken)
            const addresses = await API.loadAddressesInfo(props.authToken)
            const agitationAvailable = await API.getMyAgitationMaterials(props.authToken)
            // @ts-ignore
            await globalStore.dispatch(thunkUpdateLoadedDataAction(additionalInfo, addresses, agitationAvailable.rows))
            setIsLoading(false)
        }

        const handleErr = async (err: Error) => {
            if (err.message === 'NetworkError when attempting to fetch resource.') {
                const settingsFromDb = await DB.getGlobalSettings()
                // @ts-ignore
                await globalStore.dispatch(thunkUpdateLoadedDataAction(
                    settingsFromDb.info,
                    settingsFromDb.addresses,
                    settingsFromDb.agitation
                ))
                setIsLoading(false)
            } else {
                globalStore.dispatch(updateAuthToken(''))
            }
        }
        if (props.authToken) {
            API.checkHealth(props.authToken).then(() => {
                loadData().catch(err => {
                    console.log('Load data failed', err)
                    handleErr(err)
                })
            }).catch(err => {
                console.log('Health check failed', err)
                handleErr(err)
            })
        }
    })

    const showApp = isLoggedIn && !isLoading
    return (
        <ToastProvider autoDismiss={true} placement={'top-center'} autoDismissTimeout={2000}>
            <MuiPickersUtilsProvider utils={MomentUtils}>

                <div className="App">
                    {
                        showApp &&
                        <Router>
                            <Switch>
                                <Route exact path="/home">
                                    <HomeScreen/>
                                </Route>
                                <Route exact path="/profile">
                                    <ProfileScreen/>
                                </Route>
                                <Route exact path="/communication">
                                    <HqCommunicationScreen/>
                                </Route>
                                <Route exact path="/myroute" render={(props) => (
                                    <MyRouteScreen {...props}/>
                                )}>

                                </Route>
                                <Route exact path="/history">
                                    <HistoryScreen/>
                                </Route>
                                <Route>
                                    <Redirect to="/home"/>
                                </Route>
                            </Switch>
                        </Router>
                    }
                    {
                        !isLoggedIn && <AuthScreen/>
                    }
                    {
                        isLoggedIn && !showApp && <AppSpinner/>
                    }
                    <div className='app-version-container'>
                        v1.12
                    </div>
                </div>
            </MuiPickersUtilsProvider>
        </ToastProvider>
    );
}

function mapStateToProps(state: GlobalState): Props {
    return {
        authToken: state.authToken
    }
}

export default connect(
    mapStateToProps
)(App);
