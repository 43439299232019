import React, {useEffect, useState} from 'react';
import './AuthScreen.css'

import ImCheckSvgLogo from '../assets/icons/im-check.svg'
import * as API from "../api/API";
import {useToasts} from "react-toast-notifications";
import {globalStore} from "../utils/redux/ReduxUtils";
import {updateAuthToken} from "../utils/redux/Actions";
import * as LocalStorage from "../utils/LocalStorage";
import moment from "moment";

export default function AuthScreen() {
    const [login, setLogin] = useState("")
    const [password, setPassword] = useState("")
    const { addToast } = useToasts()

    const tryLogin = async () => {
        if (login && password) {
            try {
                const {token} = await API.authorize(login, password)
                globalStore.dispatch(updateAuthToken(token))
            } catch (err) {
                if (err.message === 'NetworkError when attempting to fetch resource.') {
                    addToast('Нет связи с сервером!', { appearance: 'error' })
                } else addToast('Неверный логин или пароль', { appearance: 'error' })
                globalStore.dispatch(updateAuthToken(''))
                console.log("unauthorized", err)
            }
        }
    }
    useEffect(() => {
        let lastSyncMoment = LocalStorage.getLastSyncMoment();
        if (!lastSyncMoment) {
            LocalStorage.setLastSyncMoment(moment().valueOf());
        }
    }, [])

    return (
        <div className="antialiased bg-light text-dark d-flex flex-column min-vh-100 w-100">
            <main className="container-fluid" style={{maxWidth: '800px'}}>

                <div className="text-center my-4 mt-sm-5">
                    <h1 className="font-weight-bold fw-7 user-select-none logo logo-lg align-content-start">
                        Электор
                        <img style={{width: '32px'}} src={ImCheckSvgLogo}/>
                    </h1>
                    <h6>Приложение для предвыборных кампаний</h6>
                </div>

                <div className="mx-auto mb-3">
                    <div className="card">
                        <div className="card-header bg-dark text-white text-center h6 py-3">Вход в аккаунт</div>
                        <div className="card-body p-4">
                            <div className="mb-3">
                                <label htmlFor="auth-error" className="form-label">Логин</label>
                                <span id="auth-error" className="float-right text-danger d-none">Неверный пароль или почта</span>
                                <input value={login} id="auth-email" className="form-control"
                                       placeholder="elector-scout"
                                       onChange={e => setLogin(e.target.value)}
                                       type="text" required/>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Пароль</label>
                                <input value={password} id="auth-password" className="form-control"
                                       placeholder="password"
                                       onChange={e => setPassword(e.target.value)}
                                       type="password" required/>
                            </div>
                            <button className="btn btn-primary btn-block fw-5 mb-3" onClick={tryLogin}>
                                Войти
                            </button>

                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}
