import {ContactModel} from "../models/ContactModel";
import {ApiContactInfoResponseRow} from "./ResponseModels";

export function mapApiContactToContact(c: ApiContactInfoResponseRow): ContactModel {
    return {
        id: c.id,
        name: c.name,
        surname: c.surname,
        patronymic: c.patronymic,
        gender: c.gender,
        reaction: c.reaction,
        thematics: c.thematics,
        age: c.age,
        phoneNumber: c.phoneNumber,
        email: c.email,
        comment: c.comment,
        contactType: c.contactType,
        geo: c.geo,
        fullAddress: c.fullAddress,
    }
}
