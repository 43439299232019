import {Spinner} from "react-bootstrap-v5";
import React from "react";


const AppSpinner = () => {
    return (
        <div className='w-100 mt-5 container d-flex flex-column align-items-center justify-content-center m-auto'>
            <Spinner style={{height: 100, width: 100}} animation="border" variant='primary' role="status">
            </Spinner>
            <span className="sr-only">Загрузка...</span>
        </div>
    )
}

export default AppSpinner