import {ContactModel} from "../models/ContactModel";
import {ApiContactGeo, UpsertContactRequest} from "./RequestModels";
import moment from "moment";

export function momentToFormattedString(moment: moment.Moment): string {
    return moment.format('YYYY-MM-DDTHH:mm:ss.SSS')
}
export function mapContactToRequest(c: ContactModel, geo?: ApiContactGeo): UpsertContactRequest {
    return {
        id: c.id,
        name: c.name,
        surname: c.surname,
        patronymic: c.patronymic,
        gender: c.gender,
        reaction: c.reaction,
        thematics: c.thematics,
        age: c.age,
        phoneNumber: c.phoneNumber,
        email: c.email,
        comment: c.comment,
        contactType: c.contactType,
        geo: geo,
        isArchived: false,
        createdAt: c.createdAt? momentToFormattedString(c.createdAt): undefined
    }
}
