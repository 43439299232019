import Select from "react-dropdown-select";
import {ApiAppAgitationRemainingResponseRow} from "../../../api/ResponseModels";
import NumericInput from "react-numeric-input";
import {Button} from "react-bootstrap-v5";
import * as React from "react";
import {useEffect, useState} from "react";
import * as API from "../../../api/API";
import {globalStore} from "../../../utils/redux/ReduxUtils";
import {updateAgitationData} from "../../../utils/redux/Actions";
import {useToasts} from "react-toast-notifications";
import {thunkSaveLocalMaterialDistributionAction} from "../../../utils/redux/ThunkActions";
import {AgitationDistributionStorable} from "../../../models/DbStorableModels";
import * as TypedUtils from '../../../utils/TypedUtils'
import {ApiAppCreateAgitationGeoDistributionRequest} from "../../../api/RequestModels";
import {MaterialDistributionStatus} from "../../../models/enums";

interface Props {
    agitation: ApiAppAgitationRemainingResponseRow[]
    houseId?: number
    entranceNo?: number
}

export const AgitationGiveSection = (props: Props) => {
    const {addToast} = useToasts()

    const nonEmptyAgitations = props.agitation.filter(a => a.remaining > 0)
    const [isSending, setIsSending] = useState(false)
    const [selectedAgitation, setSelectedAgitation] = useState(props.agitation.length > 0 ? props.agitation[0] : undefined)
    const [agitationCount, setAgitationCount] = useState(1)
    const [comment, setComment] = useState('')
    const [showCommentSection, setShowCommentSection] = useState(false)
    useEffect(() => {
        setAgitationCount(1)
        setSelectedAgitation(props.agitation.length > 0 ? props.agitation[0] : undefined)
        setComment('')
    }, [props.agitation])

    const onSaveAgitationPressed = (status: MaterialDistributionStatus) => {
        setIsSending(true)
        const materialDistributionModel: ApiAppCreateAgitationGeoDistributionRequest = {
            materialId: selectedAgitation?.materialId || 0, // should never happen
            houseId: Boolean(props.houseId) ? props.houseId : undefined,
            count: status === MaterialDistributionStatus.SUCCESS? agitationCount: 0,
            status: status,
            comment: comment.trim().length > 0 ? comment.trim() : undefined,
            entranceNo: Boolean(props.entranceNo)? props.entranceNo: undefined
        }

        setTimeout(() => {
            API.createAgitationDistribution(materialDistributionModel, globalStore.getState().authToken).then(
                resp => {
                    const text = status === MaterialDistributionStatus.SUCCESS? 'Разноска успешно сохранена': 'Информация о разноске записана'
                    addToast(text, {appearance: "success"})
                    globalStore.dispatch(updateAgitationData(resp.rows))
                }
            ).catch(err => {
                if (err.message === 'NetworkError when attempting to fetch resource.') {
                    const modelForSave: AgitationDistributionStorable = {
                        ...materialDistributionModel,
                        ...{localUUID: TypedUtils.generateUUIDV4()}
                    }
                    // @ts-ignore
                    globalStore.dispatch(thunkSaveLocalMaterialDistributionAction(modelForSave))
                    addToast('Нет связи с сервером, выдача материала сохранена локально', {appearance: 'warning'})
                } else {
                    addToast('Не удалось раздать материал!', {appearance: "error"})
                }
                console.log(err)
            }).finally(() => setIsSending(false))
        }, 500)

    }
    const handleCommentChanged = (text: string) => {
        if (text.length === 0) {
            setShowCommentSection(false)
        }
        setComment(text)
    }
    if (nonEmptyAgitations.length === 0) return null
    return (
        <div
            className={'max-w-md mx-auto w-100 d-flex gx-2 d-flex flex-column justify-content-center'}>
            <h2 className='row justify-content-center'>Агитация</h2>
            <div className='col-10 mx-auto mb-2'>
                <Select
                    closeOnSelect
                    searchable
                    searchBy={'title'}
                    labelField={'title'}
                    valueField={'materialId'}
                    placeholder='Выберите материал...'
                    values={selectedAgitation ? [selectedAgitation] : []}
                    onChange={(rows: ApiAppAgitationRemainingResponseRow[]) => {
                        if (rows.length > 0) setSelectedAgitation(rows[0])
                        else setSelectedAgitation(undefined)
                    }}
                    options={props.agitation}/>
            </div>
            <div className={'col-10 d-flex flex-row justify-content-between align-items-start mx-auto'}>
                <div className='text-center d-flex flex-column justify-content-between'>
                    <label className='mb-1'>Количество:</label>
                    <NumericInput mobile
                                  value={agitationCount}
                                  max={selectedAgitation?.remaining} min={1} step={1}
                                  onChange={v => setAgitationCount(v || 1)}
                                  className='my-route-numeric-input'
                    />
                    {
                        !showCommentSection &&
                        <a className='clickable-href mt-2' onClick={() => setShowCommentSection(true)}>
                            + комментарий
                        </a>
                    }

                </div>
                <div className='col-6 d-flex flex-column'>
                    <Button style={{height: 42.5}}
                            disabled={!Boolean(selectedAgitation) || !Boolean(agitationCount) || isSending}
                            onClick={() => onSaveAgitationPressed(MaterialDistributionStatus.SUCCESS)}
                            className='col-auto mb-3' variant='primary' size='lg'>
                        <div>Выдал</div>
                    </Button>
                    <Button style={{height: 42.5}}
                            disabled={!Boolean(selectedAgitation) || !Boolean(agitationCount) || isSending}
                            onClick={() => onSaveAgitationPressed(MaterialDistributionStatus.FAILURE)}
                            className='col-auto' variant='danger' size='lg'>
                        <div>Неудачно</div>
                    </Button>
                </div>
            </div>
            {
                showCommentSection &&
                <div className="col-10 mx-auto">
                    <label htmlFor="сomment" className="float-end text-muted"><small>
                        <span>{comment.length || 0}</span> / 1000</small></label>
                    <textarea id="сomment" className="form-control mb-"
                              style={{minHeight: '5em', maxHeight: '20em'}} placeholder="—"
                              value={comment || ''}
                              onChange={e => handleCommentChanged(e.currentTarget.value)}
                              maxLength={1000} defaultValue={""}/>
                </div>
            }

        </div>
    )
}
